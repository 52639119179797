import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import CreateVault from '../pages/CreateVault';
import Disclaimer from '../pages/Disclaimer';
import EditVaultWrapper from '../pages/EditVaultWrapper';
import EntryPage from '../pages/EntryPage';
import ErrorBoundary from '../pages/ErrorBoundary';
import MyLiquidity from '../pages/MyLiquidity';
import NotFound from '../pages/NotFound';
import PalmOverviewPage from '../pages/PalmOverview';
import PalmVault from '../pages/PalmVault';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import V1VaultsPage from '../pages/V1Vaults';
import V2Vaults from '../pages/V2Vaults';

import * as Sentry from '@sentry/react';
import React from 'react';

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  tracesSampleRate: 1.0,
  environment: import.meta.env.VITE_ENV,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

// redirect old vault urls, e.g. /vaults/10/0x2817e729178471dbac8b1fc190b4fd8e6f3984e3 to /vaults/0x2817e729178471dbac8b1fc190b4fd8e6f3984e3
function CustomRoute({ ...props }) {
  const location = useLocation();
  const path = location.pathname;
  const match = path.match(/^\/vaults\/([^/]+)\/(.+)$/);
  if (match && !/^0x/.test(match[1])) {
    const newUrl = `/vaults/${match[2]}`;
    return <Navigate to={newUrl} replace />;
  }
  return <Route path={path} {...props} />;
}

export const router = sentryCreateBrowserRouter(
  [
    {
      path: '*',
      element: <NotFound />,
    },
    {
      path: '/',
      element: <EntryPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/create',
      element: <CreateVault />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/vault-edit/:vaultId',
      element: <EditVaultWrapper />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/vaults',
      element: <V1VaultsPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/my-liquidity',
      element: <MyLiquidity />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/vaults/:vaultId',
      element: <V1VaultsPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/vaults/:networkId/:vaultId',
      element: <CustomRoute />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/my-palm-vaults',
      element: <PalmOverviewPage />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/my-palm-vaults/vaults/:vaultId',
      element: <PalmVault />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/disclaimer',
      element: <Disclaimer />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/v2-vaults',
      element: <V2Vaults />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: '/v2-vaults/:vaultId',
      element: <V2Vaults />,
      errorElement: <ErrorBoundary />,
    },
  ],
  {
    basename: import.meta.env.VITE_BASE_PATH || '/',
  },
);
