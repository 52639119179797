import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect, useMemo } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import { URL } from '../../../../../utils/api';
import { getSqrtPriceX96, sqrtPriceX96ToOwnTokenPriceUsd } from '../../../../../utils/vault-creation';
import PriceItemsLoading from './PriceItemsLoading';
import PriceReferences from './PriceReferences';

import { z } from 'zod';
import { DEFAULT_LOGO_URI } from '../../../../../constants';
import SqrtPriceX96Input from './SqrtPriceX96Input';
interface PriceCheckerProps {
  ownTokenAddress: string;
  token0: string;
  baseTokenAddress: string;
  ownTokenDecimals?: number;
  baseTokenDecimals: number;
  setManualSqrtPriceX96: React.Dispatch<React.SetStateAction<bigint | undefined>>;
  sqrtPriceX96?: bigint;
  setOwnTokenPriceUsd: UseFormSetValue<{
    ownTokenPriceUsd: number | null;
  }>;
  setPrice0: React.Dispatch<React.SetStateAction<number | null>>;
  setPrice1: React.Dispatch<React.SetStateAction<number | null>>;
  ownTokenLogoUrl?: string;
  ownTokenSymbol?: string;
  customOwnTokenPriceControl: Control<
    {
      customOwnTokenPriceUsd: number | null;
    },
    any
  >;
  customOwnTokenPriceUsd: number | null;
  setCustomOwnTokenPriceUsd: UseFormSetValue<{
    customOwnTokenPriceUsd: number | null;
  }>;
}

const PriceResponseSchema = z.object({
  db: z.number().nullable(),
  coingecko: z.number().nullable(),
  coinmarketcap: z.number().nullable(),
});

function PriceChecker({
  ownTokenAddress,
  baseTokenAddress,
  ownTokenDecimals,
  baseTokenDecimals,
  setManualSqrtPriceX96,
  sqrtPriceX96,
  setOwnTokenPriceUsd,
  setPrice0,
  setPrice1,
  token0,
  ownTokenLogoUrl,
  ownTokenSymbol,
  customOwnTokenPriceUsd,
  setCustomOwnTokenPriceUsd,
  customOwnTokenPriceControl,
}: PriceCheckerProps) {
  const { data: ownTokenPrices, isLoading: isLoadingOwnTokenPrices } = useQuery(
    ['token-price', ownTokenAddress],
    async () => {
      const res = await axios.get(`${URL}/api/token/price/${ownTokenAddress}`);

      const parsedPrices = PriceResponseSchema.safeParse(res.data);

      if (parsedPrices.success) {
        const ownTokenPrice = parsedPrices.data.coingecko || parsedPrices.data.coinmarketcap;

        setOwnTokenPriceUsd('ownTokenPriceUsd', ownTokenPrice);
        token0 === ownTokenAddress ? setPrice0(ownTokenPrice) : setPrice1(ownTokenPrice);

        return parsedPrices.data;
      }
      return null;
    },
    {
      enabled: !!ownTokenAddress,
    },
  );

  const { data: baseTokenPrice, isLoading: isLoadingBaseTokenPrice } = useQuery(
    ['token-price', baseTokenAddress],
    async () => {
      const res = await axios.get(`${URL}/api/token/price/${baseTokenAddress}`);

      const parsedPrices = PriceResponseSchema.safeParse(res.data);

      if (parsedPrices.success) {
        const price = parsedPrices.data.coingecko || parsedPrices.data.coinmarketcap || parsedPrices.data.db;
        token0 === ownTokenAddress ? setPrice1(price) : setPrice0(price);

        return price;
      }
      return null;
    },
    {
      enabled: !!ownTokenAddress,
    },
  );

  useEffect(() => {
    const ownTokenPrice = ownTokenPrices?.coingecko || ownTokenPrices?.coinmarketcap;
    if (ownTokenPrice && baseTokenPrice && ownTokenDecimals) {
      setManualSqrtPriceX96(
        getSqrtPriceX96({
          ownTokenAddress,
          baseTokenAddress,
          ownTokenAmount: baseTokenPrice / ownTokenPrice,
          baseTokenAmount: 1,
          ownTokenDecimals,
        }),
      );
    } else {
      setManualSqrtPriceX96(undefined);
    }
  }, [ownTokenPrices, baseTokenPrice, baseTokenAddress, ownTokenAddress, ownTokenDecimals, setManualSqrtPriceX96]);

  const sqrtPriceX96Usd = useMemo(() => {
    if (!sqrtPriceX96 || !baseTokenPrice) return null;
    return sqrtPriceX96ToOwnTokenPriceUsd({
      sqrtPriceX96,
      ownTokenAddress,
      baseTokenAddress,
      ownTokenDecimals: ownTokenDecimals || 18,
      baseTokenDecimals,
      baseTokenPrice,
    });
  }, [sqrtPriceX96, ownTokenAddress, baseTokenAddress, baseTokenPrice, ownTokenDecimals, baseTokenDecimals]);

  const isLoading = isLoadingOwnTokenPrices || isLoadingBaseTokenPrice;

  const allowCustomToken = import.meta.env.VITE_ALLOW_CUSTOM_TOKENS === 'true';

  return (
    <div className="border-t border-[#1F1F21] pt-8 flex flex-col gap-8">
      <div className="flex flex-col gap-3">
        <h2 className="text-[20px] ">Price Checks</h2>
        <p className="text-primary-light text-[14px]">
          It is important that you set your own initial price when creating an Arrakis Pool correctly.
        </p>
      </div>
      {isLoading && <PriceItemsLoading />}
      {ownTokenPrices && !isLoading && (
        <PriceReferences
          coingeckoPrice={ownTokenPrices.coingecko}
          coinmarketcapPrice={ownTokenPrices.coinmarketcap}
          sqrtPriceX96Usd={sqrtPriceX96Usd}
        />
      )}{' '}
      {allowCustomToken &&
        baseTokenPrice &&
        !isLoading &&
        !ownTokenPrices?.coingecko &&
        !ownTokenPrices?.coinmarketcap && (
          <SqrtPriceX96Input
            ownTokenLogoUrl={ownTokenLogoUrl || DEFAULT_LOGO_URI}
            setManualSqrtPriceX96={setManualSqrtPriceX96}
            ownTokenAddress={ownTokenAddress}
            baseTokenAddress={baseTokenAddress}
            ownTokenDecimals={ownTokenDecimals || 18}
            baseTokenPrice={baseTokenPrice}
            ownTokenSymbol={ownTokenSymbol}
            customOwnTokenPriceUsd={customOwnTokenPriceUsd}
            control={customOwnTokenPriceControl}
            setCustomOwnTokenPriceUsd={setCustomOwnTokenPriceUsd}
          />
        )}
    </div>
  );
}

export default PriceChecker;
