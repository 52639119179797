import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Skeleton from 'react-loading-skeleton';
import { formatUnits, parseUnits } from 'viem';
import {
  useAccount,
  useBalance,
  useContractRead,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import { ARRAKIS_V2_PALM_MANAGER_ABI } from '../../../abis/ArrakisV2PalmManager';
import { ARRAKIS_V2_PALM_MANAGER, ERROR_MESSAGE } from '../../../constants';
import { formatBigNumber } from '../../../utils/format-big-number';
import { ExplorerDataType, getExplorerLink } from '../../../utils/get-explorer-link';
import { roundDownToNDecimals } from '../../../utils/math';
import { numberWithCommas, shortenNumber } from '../../../utils/n-formatter';
import { getNativeTokenLogoByChainId, getTokenEstimationByNetworkFor3Months } from '../../../utils/vault-creation';
import Badge from '../../elements/buttons/Badge';
import TransactionButtonWrapper from '../../elements/buttons/TransactionButtonWrapper';
import { alertUser } from '../../elements/notify';
import { Spinner } from '../../elements/spinner';

interface GasTankProps {
  vaultId: string;
  chainId: number;
}

function GasTank({ vaultId, chainId }: GasTankProps) {
  const { address } = useAccount();

  // native token balance
  const { data: nativeBalance, isLoading: isLoadingNativeBalance } = useBalance({
    address: address as `0x${string}`,
    chainId: chainId,
    enabled: !!address,
  });

  // get gas tank balance
  // hack: to check if transaction was successful with safe app, we watch token balances of vault and trigger success if they increase
  const [isDepositToGasTankSuccess, setIsDepositToGasTankSuccess] = useState(false);
  const [maxGastankBalance, setMaxGastankBalance] = useState(0);
  const [selectedPeriod, setSelectedPeriod] = useState<3 | 6 | 9 | 12>(3);
  const [showGasTank, setShowGasTank] = useState(false);

  const { amount, token } = getTokenEstimationByNetworkFor3Months(chainId);

  const { data: gasTankBalance, isLoading: isLoadingGasTankBalance } = useContractRead({
    address: ARRAKIS_V2_PALM_MANAGER,
    abi: ARRAKIS_V2_PALM_MANAGER_ABI,
    functionName: 'getVaultInfo',
    args: [vaultId],
    enabled: !!address && !!nativeBalance?.value,
    watch: true,
    select: (data) =>
      (data as any)?.balance
        ? formatBigNumber((data as any)?.balance, nativeBalance?.decimals ?? 18, nativeBalance?.decimals ?? 18)
        : '0',
    onSuccess: (newBalance: string) => {
      if (Number(newBalance) > maxGastankBalance) {
        if (maxGastankBalance > 0) {
          setIsDepositToGasTankSuccess(true);
        }
        setMaxGastankBalance(Number(newBalance));
      }
    },
  });

  // alert user when success turns true
  useEffect(() => {
    if (isDepositToGasTankSuccess) {
      alertUser('success', 'Transaction successful!');
      setValue('input', 0);
    }
  }, [isDepositToGasTankSuccess]);

  // input form
  const {
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<{ input: number }>({
    defaultValues: {
      input: 0,
    },
    mode: 'onChange',
  });

  const parsedInput = useMemo(() => {
    try {
      if (getValues('input')) return parseUnits(getValues('input').toString(), nativeBalance?.decimals || 18);
      return BigInt(0);
    } catch (error) {
      return BigInt(0);
    }
  }, [getValues('input'), nativeBalance?.decimals]);

  // deposit to gas tank

  /*  const { gasEstimate } = useEstimateGas({
    address: ARRAKIS_V2_PALM_MANAGER,
    abi: ARRAKIS_V2_PALM_MANAGER_ABI,
    functionName: 'fundVaultBalance',
    args: [vaultId],
    value: parsedInput,
    account: address as `0x${string}`,
  }); */

  const addRecentTransaction = useAddRecentTransaction();

  const { config: depositToGasTankConfig } = usePrepareContractWrite({
    address: ARRAKIS_V2_PALM_MANAGER,
    chainId: chainId,
    abi: ARRAKIS_V2_PALM_MANAGER_ABI,
    functionName: 'fundVaultBalance',
    args: [vaultId],
    value: parsedInput,
  });

  const { data: depositToGasTankData, write: depositToGasTank } = useContractWrite({
    ...(depositToGasTankConfig as any),
    onError() {
      alertUser('error', ERROR_MESSAGE);
    },
  });

  const { isLoading: depositToGasTankLoading } = useWaitForTransaction({
    hash: depositToGasTankData?.hash,
    onSettled(data, error) {
      if (error) {
        alertUser('error', ERROR_MESSAGE);
        return;
      }
      alertUser(
        'success',
        'Liquidity added successful!',
        getExplorerLink(chainId || 137, depositToGasTankData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
      );

      addRecentTransaction({
        hash: depositToGasTankData?.hash ?? '',
        description: 'Deposit to Gas Tank',
      });
    },
  });

  useEffect(() => {
    const newGasAmount = roundDownToNDecimals(
      (getTokenEstimationByNetworkFor3Months(chainId).amount * selectedPeriod) / 3,
      1,
    );
    setValue('input', newGasAmount);
  }, [selectedPeriod, chainId, setValue]);

  const isDepositButtonDisabled = !!errors.input || !depositToGasTank;

  const nativeBalanceFormatted = shortenNumber(Number(nativeBalance?.formatted));

  function getMaxInputValue() {
    if (nativeBalance?.value) {
      const max = nativeBalance.value;
      return formatUnits(max, nativeBalance.decimals || 18);
    }
    return undefined;
  }

  const maxInputRule = useMemo(() => {
    const maxInput = getMaxInputValue();

    return maxInput ? { value: maxInput, message: 'Not enough funds (including gas)' } : undefined;
  }, [nativeBalance?.value, parsedInput]);

  return (
    <div>
      <button className="text-[20px] mt-5 flex items-center gap-2">
        Gas Information
        {showGasTank ? (
          <ChevronUpIcon
            onClick={() => {
              setShowGasTank(false);
            }}
            className="h-6 w-6 text-vault-gray-hover"
          />
        ) : (
          <ChevronDownIcon
            onClick={() => {
              setShowGasTank(true);
            }}
            className="h-6 w-6 text-vault-gray-hover"
          />
        )}
      </button>

      {showGasTank && (
        <div className="mt-5 flex flex-col lg:flex-row gap-4 justify-between">
          <div className="flex gap-12 whitespace-nowrap">
            <div>
              <h4 className="text-vault-gray text-[16px]">Gas Tank</h4>
              {isLoadingGasTankBalance && (
                <Skeleton height="20px" width="110px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
              )}
              {!isLoadingGasTankBalance && gasTankBalance && (
                <p className="text-primary-light text-[20px]">
                  {numberWithCommas(shortenNumber(Number(gasTankBalance)))} {nativeBalance?.symbol}
                </p>
              )}
            </div>
            <div>
              <h4 className="text-vault-gray text-[16px]">Your Balance</h4>
              {isLoadingNativeBalance && (
                <Skeleton height="20px" width="110px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
              )}
              {nativeBalance?.formatted && !isLoadingNativeBalance && (
                <p className="text-primary-light text-[20px]">
                  {nativeBalanceFormatted} <span>{nativeBalance?.symbol}</span>{' '}
                </p>
              )}
            </div>
          </div>

          <div>
            <div className="mt-4 flex items-center justify-between gap-2">
              <div
                className={`flex justify-between bg-[#181818cc] px-4 py-3 rounded-[12px] border ${
                  errors.input ? 'border-red-400' : 'border-none'
                }`}
              >
                <div className="flex flex-row items-center">
                  <img
                    className="justify-self-end mb-2"
                    src={getNativeTokenLogoByChainId(chainId)}
                    alt="token logo"
                    width={25}
                    height={25}
                  />
                  <span className="ml-2 min-w-[60px]">{nativeBalance?.symbol}</span>
                </div>
                <input
                  {...register('input', {
                    valueAsNumber: true,
                    required: "Can't be empty",
                    min: {
                      value: 0,
                      message: 'Minimum value is 0',
                    },

                    max: maxInputRule,
                  })}
                  type="number"
                  step="0.000000000000000001"
                  placeholder="0.00"
                  className="text-[26px] p-0 max-w-[200px] sm:max-w-none  bg-transparent text-right text-2xl text-white border-transparent focus:border-transparent focus:ring-0"
                />
              </div>
              <TransactionButtonWrapper chainId={chainId}>
                <button
                  onClick={() => depositToGasTank?.()}
                  disabled={isDepositButtonDisabled}
                  className="rounded-lg px-5 py-1 h-[48px] w-[140px] flex items-center justify-center gap-1 disabled:bg-vault-gray-2 disabled:text-vault-gray-hover bg-primary-light text-primary-black"
                  style={isDepositButtonDisabled ? {} : { color: '#191b1f', background: 'white' }}
                >
                  <span> Deposit </span>
                  {depositToGasTankLoading && !isDepositToGasTankSuccess && (
                    <span className="ml-2">
                      <Spinner color="vault-white" />
                    </span>
                  )}
                </button>
              </TransactionButtonWrapper>
            </div>
            {errors.input && <p className="text-red-400 text-xs mt-2">{errors.input.message}</p>}

            <div className="flex flex-col gap-3 mt-5">
              <div className="flex gap-2">
                <Badge isActive={getValues('input') === amount} onClick={() => setSelectedPeriod(3)}>
                  <div className="flex flex-col gap-2">
                    <div>3 Months</div>
                    <div className="text-secondary-grey text-[12px] leading-4">
                      {amount.toFixed(2)} {token}
                    </div>
                  </div>
                </Badge>
                <Badge isActive={getValues('input') === amount * 2} onClick={() => setSelectedPeriod(6)}>
                  <div className="flex flex-col gap-2">
                    <div>6 Months</div>
                    <div className="text-secondary-grey text-[12px] leading-4">
                      {' '}
                      {(2 * amount).toFixed(2)} {token}
                    </div>
                  </div>
                </Badge>
                <Badge
                  isActive={getValues('input') === roundDownToNDecimals(amount * 3, 1)}
                  onClick={() => setSelectedPeriod(9)}
                >
                  <div className="flex flex-col gap-2">
                    <div>9 Months</div>
                    <div className="text-secondary-grey text-[12px] leading-4">
                      {' '}
                      {(3 * amount).toFixed(2)} {token}
                    </div>
                  </div>
                </Badge>
                <Badge isActive={getValues('input') === amount * 4} onClick={() => setSelectedPeriod(12)}>
                  <div className="flex flex-col gap-2">
                    <div>12 Months</div>
                    <div className="text-secondary-grey text-[12px] leading-4">
                      {' '}
                      {(4 * amount).toFixed(2)} {token}
                    </div>
                  </div>
                </Badge>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default GasTank;
