import { z } from 'zod';
import tokenList from './extendedtokens-uniswap.json';

export const TokenSchema = z.object({
  chainId: z.optional(z.number()),
  address: z.string(),
  name: z.string(),
  symbol: z.string(),
  decimals: z.number(),
  logoURI: z.optional(z.string()),
});

export type Token = z.infer<typeof TokenSchema>;

export function getUnknownToken(chainId: number, address: string): Token {
  return {
    chainId,
    address: address.toLowerCase(),
    name: 'Unknown',
    symbol: 'UNKNOWN',
    decimals: 18,
    logoURI: '/assets/images/question-mark.svg',
  };
}

export function getTokenList(chainId: number): Token[] {
  return tokenList.tokens.filter((token) => token.chainId === chainId);
}
