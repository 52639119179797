import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useQuery } from '@tanstack/react-query';
import { readContract } from '@wagmi/core';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import { zeroAddress } from 'viem';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import { ARRAKIS_V2_PALM_MANAGER_ABI } from '../abis/ArrakisV2PalmManager';
import PrimaryButton from '../components/elements/buttons/PrimaryButton';
import SecondaryButton from '../components/elements/buttons/SecondaryButton';
import Modal from '../components/elements/modal';
import { alertUser } from '../components/elements/notify';
import Simulator from '../components/forms/CreateVault/components/Simulator';
import StrategyEditor from '../components/forms/CreateVault/components/StrategyEditor';
import SqrtPriceX96Input from '../components/forms/CreateVault/components/price-checker/SqrtPriceX96Input';
import { Header } from '../components/layout/header';
import { ARRAKIS_V2_PALM_MANAGER } from '../constants';
import { DelegateStatus, useSetDelegate } from '../hooks/useSetDelegate';
import { useUpdateVault } from '../hooks/useUpdateVault';
import {
  GetVaultInfoResponseSchema,
  LiquidityConcentration,
  RebalanceSpeed,
  StrategySchema,
} from '../types/create-vault';
import { getPalmVault } from '../utils/api';
import { getStrategyFromHex } from '../utils/n-formatter';
import { getVaultProportions } from '../utils/vault';
import {
  getConcentrationConfig,
  getRatioForConcentrationConfig,
  getVault01Data,
  sqrtPriceX96ToOwnTokenPriceUsd,
} from '../utils/vault-creation';

function EditVaultWrapper() {
  const { vaultId } = useParams();

  const {
    formState: { errors },
    setValue,
    watch,
  } = useForm<{ strategy: string | undefined }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const currentStrategy = watch('strategy');

  const { address } = useAccount();
  const { chain } = useNetwork();
  const { isLoading: isLoadingSwitchNetwork, switchNetwork } = useSwitchNetwork();
  const { openConnectModal } = useConnectModal();

  const [showTransactionModal, setShowTransactionModal] = useState(false);

  const { data: vault, isLoading: isLoadingFetchPalmVault } = useQuery(
    ['palm-vault', vaultId],
    () => getPalmVault(vaultId),
    {
      refetchOnWindowFocus: false,
      enabled: !!vaultId,
      retry: 1,
    },
  );
  /*  const { data: strategyConfig } = useQuery(['vault-strategy', vaultId], () => getVaultStrategy(vaultId), {
    refetchOnWindowFocus: false,
    enabled: !!vaultId && !!vault,
  }); */

  const [originalStrategy, setOriginalStrategy] = useState<string | undefined>(undefined);

  const parsedStrategy = useMemo(() => {
    const parsed = StrategySchema.safeParse(JSON.parse(currentStrategy || '{}'));
    if (parsed.success) {
      return parsed.data;
    }
    return undefined;
  }, [currentStrategy]);

  const strategyHasChanged = originalStrategy !== currentStrategy;

  const { setDelegate, isLoadingSetDelegate, delegateStatus } = useSetDelegate({
    vaultAddress: vaultId || zeroAddress,
    chainId: vault?.chainId || 1,
    walletAddress: address || zeroAddress,
    onFinish: () => {
      setShowTransactionModal(false);
      alertUser('success', 'Your strategy has been updated');
    },
  });

  const { updateVault, isLoadingUpdateVault } = useUpdateVault({
    vaultAddress: vaultId || zeroAddress,
    currentStrategy: currentStrategy || '',
    chainId: vault?.chainId || 1,
    onSuccess: () => {
      setOriginalStrategy(currentStrategy);
    },
  });

  const {
    control: controlCustomOwnTokenPriceUsd,
    setValue: setCustomOwnTokenPriceUsd,
    watch: watchCustomOwnTokenPriceUsd,
  } = useForm<{
    customOwnTokenPriceUsd: number | null;
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const customOwnTokenPriceUsd = watchCustomOwnTokenPriceUsd('customOwnTokenPriceUsd');

  const [manualSqrtPriceX96, setManualSqrtPriceX96] = useState<bigint | undefined>(undefined);

  useEffect(() => {
    if (manualSqrtPriceX96) {
      setValue('strategy', JSON.stringify({ ...parsedStrategy, initialSqrtPriceX96: manualSqrtPriceX96.toString() }));
    }
  }, [manualSqrtPriceX96]);

  const assetIsTokenZero = parsedStrategy?.assetIsTokenZero;

  const { ownTokenAddress, baseTokenAddress, baseTokenPrice } = getVault01Data(assetIsTokenZero, vault);

  useEffect(() => {
    async function setInitialStrategy() {
      const data = await readContract({
        address: ARRAKIS_V2_PALM_MANAGER,
        abi: ARRAKIS_V2_PALM_MANAGER_ABI,
        functionName: 'getVaultInfo',
        args: [vaultId],
        chainId: vault?.chainId || 1,
      });

      const parsedVaultInfo = GetVaultInfoResponseSchema.safeParse(data);

      if (!parsedVaultInfo.success) {
        return null;
      }

      const strategyHex = parsedVaultInfo.data.datas;

      if (!strategyHex) {
        return null;
      }

      const currentStrategy = getStrategyFromHex(strategyHex);

      setValue('strategy', JSON.stringify(currentStrategy));
      setOriginalStrategy(JSON.stringify(currentStrategy));

      const { ownTokenAddress, baseTokenAddress, baseTokenPrice, ownTokenDecimals, baseTokenDecimals } = getVault01Data(
        currentStrategy?.assetIsTokenZero,
        vault,
      );

      if (
        currentStrategy?.initialSqrtPriceX96 &&
        ownTokenAddress &&
        baseTokenAddress &&
        baseTokenPrice &&
        ownTokenDecimals &&
        baseTokenDecimals
      ) {
        const tokenPrice = sqrtPriceX96ToOwnTokenPriceUsd({
          sqrtPriceX96: BigInt(currentStrategy?.initialSqrtPriceX96),
          ownTokenAddress,
          baseTokenAddress,
          ownTokenDecimals,
          baseTokenDecimals,
          baseTokenPrice,
        });

        setCustomOwnTokenPriceUsd('customOwnTokenPriceUsd', tokenPrice);
      }
    }

    if (vault) {
      setInitialStrategy();
    }
  }, [vault]);

  const vaultProportions = vault ? getVaultProportions(vault) : { percentage0Str: '50', percentage1Str: '50' };

  const tokenRatio =
    Number(assetIsTokenZero ? vaultProportions.percentage0Str : vaultProportions.percentage1Str) ?? 0.5;

  return (
    <>
      <Header />

      <div className="mx-auto container max-w-screen-xl text-white py-8">
        <div>
          {vault && currentStrategy && parsedStrategy && (
            <>
              <div className="flex flex-col gap-10 md:grid md:grid-cols-2 pb-10">
                {parsedStrategy && (vault.pools?.length || 0 > 0) && vault.pools && (
                  <div className="flex flex-col gap-8">
                    <StrategyEditor
                      setValue={setValue}
                      strategy={currentStrategy}
                      error={errors.strategy}
                      ownTokenAddress={ownTokenAddress as string}
                      baseTokenAddress={baseTokenAddress as string}
                      tokenRatio={tokenRatio}
                      feeTier={parsedStrategy.feeTier}
                      chainId={vault.chainId}
                      // only use sqrtPriceX96 in config if we have no coingecko price
                      sqrtPriceX96={
                        parsedStrategy.initialSqrtPriceX96 ? BigInt(parsedStrategy.initialSqrtPriceX96) : undefined
                      }
                      initialValues={{
                        concentration:
                          getConcentrationConfig(
                            parsedStrategy.feeTier,
                            getRatioForConcentrationConfig(tokenRatio),
                            parsedStrategy.wideRangeActiveAmountInToken1BPS,
                          ) ?? LiquidityConcentration.moderate,
                        rebalanceSpeed: RebalanceSpeed.moderate,
                        maxGasPrice: (parsedStrategy.maxGasPrice / 1e9).toString(),
                        twapDuration: parsedStrategy.twapDuration,
                        maxTwapDeviation: parsedStrategy.maxTwapDeviation,
                      }}
                      type="edit"
                    ></StrategyEditor>
                    {parsedStrategy?.initialSqrtPriceX96 && (
                      <SqrtPriceX96Input
                        ownTokenLogoUrl={assetIsTokenZero ? vault.token0LogoUrl : vault.token1LogoUrl}
                        setManualSqrtPriceX96={setManualSqrtPriceX96}
                        ownTokenAddress={ownTokenAddress as string}
                        baseTokenAddress={baseTokenAddress as string}
                        ownTokenDecimals={assetIsTokenZero ? vault.token0Decimals : vault.token1Decimals}
                        baseTokenPrice={baseTokenPrice as number}
                        ownTokenSymbol={assetIsTokenZero ? vault.symbol0 : vault.symbol1}
                        customOwnTokenPriceUsd={customOwnTokenPriceUsd}
                        control={controlCustomOwnTokenPriceUsd}
                        setCustomOwnTokenPriceUsd={setCustomOwnTokenPriceUsd}
                      />
                    )}
                  </div>
                )}

                <Simulator
                  ratio={tokenRatio}
                  token0={vault.token0}
                  token1={vault.token1}
                  wideRangeActiveAmountInToken1BPS={parsedStrategy.wideRangeActiveAmountInToken1BPS}
                  wideRangeAmount0Bps={parsedStrategy.wideRangeAmount0Bps}
                  wideRangeAmount1Bps={parsedStrategy.wideRangeAmount1Bps}
                  feeTier={parsedStrategy.feeTier}
                  setPrice0={() => console.log('setPrice0')}
                  setPrice1={() => console.log('setPrice1')}
                  totalAmountUsd={vault.tvl}
                  ownTokenAddress={vault.token0}
                  token0LogoUrl={vault.token0LogoUrl}
                  token1LogoUrl={vault.token1LogoUrl}
                  token0Symbol={vault.symbol0}
                  token1Symbol={vault.symbol1}
                  token0Decimals={vault.token0Decimals}
                  token1Decimals={vault.token1Decimals}
                  sqrtPriceX96={
                    parsedStrategy.initialSqrtPriceX96 ? BigInt(parsedStrategy.initialSqrtPriceX96) : undefined
                  }
                />
              </div>
              <div className="w-full justify-between flex">
                {!address && (
                  <PrimaryButton onClick={() => openConnectModal?.()} type="button" isLoading={isLoadingSwitchNetwork}>
                    Connect Wallet to update strategy
                  </PrimaryButton>
                )}

                {address && vault.chainId !== chain?.id && (
                  <PrimaryButton
                    onClick={() => switchNetwork?.(vault.chainId)}
                    type="button"
                    isLoading={isLoadingSwitchNetwork}
                  >
                    Switch Network to update strategy
                  </PrimaryButton>
                )}

                {address && vault.chainId === chain?.id && (
                  <PrimaryButton disabled={!strategyHasChanged} onClick={() => setShowTransactionModal(true)}>
                    Update Strategy
                  </PrimaryButton>
                )}
                <Link to={`/palm`}>
                  <SecondaryButton type="button" disabled={!vaultId}>
                    Back to vaults
                  </SecondaryButton>
                </Link>
                <Modal isOpen={showTransactionModal} closeModal={() => setShowTransactionModal(false)}>
                  <h3 className="text-[20px] text-vault-white ">Update Strategy</h3>
                  <p className="text-[14px] text-primary-light">
                    You need to do all three steps to update your strategy
                  </p>
                  <div className="flex flex-col sm:flex-row sm:items-center gap-2 w-full">
                    <PrimaryButton
                      disabled={!setDelegate || delegateStatus === DelegateStatus.OWNER}
                      isLoading={isLoadingSetDelegate && delegateStatus === DelegateStatus.ARRAKIS}
                      onClick={() => setDelegate?.()}
                      className="grow disabled:grow-0 flex"
                    >
                      Prepare Strategy Update
                    </PrimaryButton>

                    <PrimaryButton
                      disabled={
                        !updateVault ||
                        isLoadingFetchPalmVault ||
                        delegateStatus === DelegateStatus.ARRAKIS ||
                        !strategyHasChanged
                      }
                      isLoading={isLoadingUpdateVault}
                      onClick={() => updateVault?.()}
                      className="grow disabled:grow-0"
                    >
                      Update Strategy
                    </PrimaryButton>
                    <PrimaryButton
                      disabled={!setDelegate || delegateStatus === DelegateStatus.ARRAKIS || strategyHasChanged}
                      onClick={() => setDelegate?.()}
                      isLoading={isLoadingSetDelegate && delegateStatus === DelegateStatus.OWNER}
                      className="grow disabled:grow-0"
                    >
                      Finish Strategy Update
                    </PrimaryButton>
                  </div>
                </Modal>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default EditVaultWrapper;
