import { ComponentPropsWithoutRef } from 'react';

interface ImageProps extends Omit<ComponentPropsWithoutRef<'img'>, 'src' | 'alt'> {
  src: string;
  alt: string;
}

export const Image = ({ src, alt, ...props }: ImageProps) => (
  <img src={`${import.meta.env.VITE_BASE_PATH}${src}`} alt={alt} {...props} />
);
