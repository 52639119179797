import axios from 'axios';
import { IVault, SwapAndAddLiquidityArgs, SwapAndAddLiquidityPermit2Args } from '../../types';
import { URL } from '../api';
import { getAddLiquidityPermit2Params } from '../blockchain-communication';
import { getRandomInt } from '../math';
import { getPermittedArray } from '../permit2';
import {
  GetSwapAndAddLiquidityParamsProps,
  GetSwapAndAddLiquidityPermit2ParamsProps,
  GetSwapParametersFromSwapResolverProps,
  GetSwapResponse,
  GetSwapResponseSchema,
} from './types';

/**
 * Get arguments for the swapAndAddLiquidity smart contract function on arrakis v2 router
 * @param vault - vault object
 * @param amount0 - amount of token0 to swap
 * @param amount1 - amount of token1 to swap
 * @param selectedZapinToken - 0 for token0, 1 for token1
 * @param slippage - slippage in %
 * @param walletAddress - wallet address of user
 *   **/
export async function getSwapAndAddLiquidityArgs({
  vault,
  amount0,
  amount1,
  selectedZapinToken,
  slippage,
  walletAddress,
}: GetSwapAndAddLiquidityParamsProps): Promise<SwapAndAddLiquidityArgs> {
  try {
    const swapParameters = await getSwapParametersFromSwapResolver({
      vault,
      amount0,
      amount1,
      selectedZapinToken,
      slippage,
    });

    const mintAmounts = await getMintAmounts({
      vault,
      selectedZapinToken,
      amount0,
      amount1,
      swapParameters,
      slippageInPercent: slippage,
    });

    const swapAndAddLiquidityArgs: SwapAndAddLiquidityArgs = [
      {
        swapData: {
          swapPayload: swapParameters.swapPayload,
          amountInSwap: swapParameters.swapAmountIn,
          amountOutSwap: swapParameters.minAmountOut,
          swapRouter: swapParameters.swapRouter,
          zeroForOne: swapParameters.zeroForOne,
          // amountOutSwap is the check: did I get at least amountOutSwap out? if not, revert with below minimum
        },
        addData: {
          amount0Max: selectedZapinToken === 1 ? '0' : amount0.toString(), // todo: remove this, for now it fixes signature problem
          amount1Max: selectedZapinToken === 0 ? '0' : amount1.toString(), // todo: remove this, for now it fixes signature problem
          amount0Min: mintAmounts.amount0.toString(),
          amount1Min: mintAmounts.amount1.toString(),
          amountSharesMin: mintAmounts.mintAmount.toString(),
          vault: vault.id,
          receiver: walletAddress,
          gauge: vault.gauge?.address || '0x0000000000000000000000000000000000000000',
        },
      },
    ];
    return swapAndAddLiquidityArgs;
  } catch (error) {
    console.log('Error while getting swap and add liquidity args', error);
    throw new Error('Error while getting swap and add liquidity args');
  }
}

/**
 * Transform arguments for the swapAndAddLiquidity smart contract function on arrakis v2 router to swapAndAddLiquidityPermit2 smart contract function

 *   **/
export function transformSwapAndAddLiquidityArgsToPermit2({
  vault,
  amount0,
  amount1,
  signature,
  swapAndAddLiquidityArgs,
  selectedZapinToken,
}: GetSwapAndAddLiquidityPermit2ParamsProps): SwapAndAddLiquidityPermit2Args {
  try {
    const nonce = getRandomInt(1, 9999999999).toString();

    const swapAndAddLiquidityPermit2Args: SwapAndAddLiquidityPermit2Args = [
      {
        swapAndAddData: swapAndAddLiquidityArgs[0],
        permit: {
          permitted: getPermittedArray({
            useNativeToken: false,
            input0: amount0,
            input1: amount1,
            vault,
            nativeSymbol0: undefined,
            selectedZapinToken,
          }),
          nonce: BigInt(nonce),
          deadline: BigInt('999999999999999'),
          // spender: ARRAKIS_V2_ROUTER_ADDRESSES,
        },
        signature,
      },
    ];
    return swapAndAddLiquidityPermit2Args;
  } catch (error) {
    console.log('Error while getting swap and add liquidity args', error);
    throw new Error('Error while getting swap and add liquidity args');
  }
}

interface CalculatePriceX18Props {
  baseTokenPrice: number;
  quoteTokenPrice: number;
}

function calculatePriceX18({ baseTokenPrice, quoteTokenPrice }: CalculatePriceX18Props) {
  return BigInt(Math.round((baseTokenPrice / quoteTokenPrice) * 10 ** 18)).toString();
}

/**
 * Get swap parameters from swap resolver api
 * @param vault - vault object
 * @param amount0 - amount of token0 to swap
 * @param amount1 - amount of token1 to swap
 * @param selectedZapinToken - 0 for token0, 1 for token1
 * @param slippage - slippage in %
 **/
async function getSwapParametersFromSwapResolver({
  vault,
  amount0,
  amount1,
  selectedZapinToken,
  slippage,
}: GetSwapParametersFromSwapResolverProps): Promise<GetSwapResponse> {
  const amount0Max = selectedZapinToken === 0 ? amount0.toString() : '0';
  const amount1Max = selectedZapinToken === 1 ? amount1.toString() : '0';

  const swapResolverArgs = {
    chainId: vault.chainId.toString(),
    token0Address: vault.token0,
    token1Address: vault.token1,
    vaultAddress: vault.id,
    token0Decimals: vault.token0Decimals,
    token1Decimals: vault.token1Decimals,
    amount0Max,
    amount1Max,
    slippage: slippage * 100,
    priceX18: calculatePriceX18({
      baseTokenPrice: vault.price0,
      quoteTokenPrice: vault.price1,
    }),
  };

  const result = await axios.put(`${URL}/api/swap-resolver/v2`, swapResolverArgs);

  const validatedSwapResponse = GetSwapResponseSchema.safeParse(result.data);

  if (!validatedSwapResponse.success) {
    throw new Error('Swap response validation failed');
  }

  return validatedSwapResponse.data;
}

interface GetMintAmountsProps {
  vault: IVault;
  selectedZapinToken: 0 | 1;
  amount0: bigint;
  amount1: bigint;
  swapParameters: GetSwapResponse;
  slippageInPercent?: number;
}

async function getMintAmounts({
  vault,
  selectedZapinToken,
  amount0,
  amount1,
  swapParameters,
  slippageInPercent = 1,
}: GetMintAmountsProps) {
  let addLiquidityParams;

  if (selectedZapinToken === 0) {
    const amount0Expected = amount0 - BigInt(swapParameters.swapAmountIn);
    const amount1Expected = BigInt(swapParameters.minAmountOut);

    addLiquidityParams = await getAddLiquidityPermit2Params(vault, amount0Expected, amount1Expected, slippageInPercent);
  } else {
    const amount0Expected = BigInt(swapParameters.minAmountOut);
    const amount1Expected = amount1 - BigInt(swapParameters.swapAmountIn);

    addLiquidityParams = await getAddLiquidityPermit2Params(vault, amount0Expected, amount1Expected, slippageInPercent);
  }

  return addLiquidityParams;
}
