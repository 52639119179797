import { Explanation } from '../../../../types';
import { SetFormValue } from '../../../../types/create-vault';
import Badge from '../../../elements/buttons/Badge';
import QuestionButton from '../../../layout/sidebar/QuestionButton';

interface BaseTokenSelectionProps {
  baseTokenAddress: string;
  setValue: SetFormValue;
  wethTokenAdress: string;
  usdcTokenAddress: string;
}

function BaseTokenSelection({
  baseTokenAddress,
  setValue,
  wethTokenAdress,
  usdcTokenAddress,
}: BaseTokenSelectionProps) {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-2">
        <h2 className="text-[16px] leading-[20px] trailing-[0.16px]">Base Token</h2>

        <QuestionButton explanation={Explanation.BASE_TOKEN} title="Base Token explained" />
      </div>

      <div className="flex items-center gap-2">
        <Badge
          isActive={baseTokenAddress === wethTokenAdress}
          onClick={() => setValue('baseTokenAddress', wethTokenAdress, { shouldValidate: true })}
        >
          <img
            src="https://assets-stage.dex.guru/icons/0x82af49447d8a07e3bd95bd0d56f35241523fbab1-arbitrum.png"
            alt="weth"
            className="rounded-full max-w-[20px] max-h-[20px] align-middle mx-0.5 opacity-90 hover:opacity-100 duration-300"
          />
          <div>WETH</div>
        </Badge>
        <Badge
          isActive={baseTokenAddress === usdcTokenAddress}
          onClick={() => setValue('baseTokenAddress', usdcTokenAddress, { shouldValidate: true })}
        >
          <img
            src="https://raw.githubusercontent.com/compound-finance/token-list/master/assets/asset_USDC.svg"
            alt="usdc"
            className="rounded-full max-w-[20px] max-h-[20px] align-middle opacity-90 hover:opacity-100 duration-300"
          />
          <div>USDC</div>
        </Badge>
      </div>
    </div>
  );
}

export default BaseTokenSelection;
