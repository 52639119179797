import { track } from '@vercel/analytics/react';
import Lottie from 'lottie-react';
import liquidityAnimation from '../../../../animations/liquidity.json';
import PrimaryButton from '../../../elements/buttons/PrimaryButton';
import SecondaryButton from '../../../elements/buttons/SecondaryButton';
interface VaultIntroProps {
  continueFn: () => void;
}

export default function VaultIntro({ continueFn }: VaultIntroProps) {
  function onContinue() {
    track('start vault creation', {
      step: 'intro',
      type: 'click',
      feature: 'vault creation',
    });
    continueFn();
  }

  return (
    <div className="mx-auto max-w-7xl flex flex-col md:flex-row md:justify-center items-center md:gap-16">
      <div className="max-w-[500px] flex flex-col gap-4 order-2 md:order-1">
        <h4 className="text-[16px] leading-5 text-vault-orange tracking-[-0.16px]">
          Create deep liquidity for your token on Uniswap V3
        </h4>
        <h2 className="text-[33px] md:text-[52px] font-semibold tracking-[-1.04px] text-white leading-[40px] md:leading-[60px]">
          Deploy your Arrakis PALM vault <span className="align-text-top text-[15px] text-vault-orange">BETA</span>
        </h2>
        <p className="text-[16px] tracking-[-0.16px] leading-6 text-primary-light">
          An Arrakis PALM vault enables you to harness the power of automated liquidity management on Uniswap V3 for any
          token pair you want. Leverage sophisticated market making strategies to deepen liquidity & optimise your fee
          earnings.
        </p>
        <div className="flex flex-wrap justify-between items-center  gap-5 md:gap-8 mt-[28px] md:mt-10">
          <div className="w-full flex items-center justify-center md:justify-start gap-4">
            <PrimaryButton onClick={onContinue}>Deploy vault</PrimaryButton>
            <a href="https://qxqhpatmzz7.typeform.com/to/IZdNgmmM" target="blank">
              <SecondaryButton>Contact us</SecondaryButton>
            </a>
          </div>
          <a
            href="https://docs.arrakis.fi/arrakis-v2-palm/overview"
            target="blank"
            className="text-center md:text-left w-full underline decoration-dashed underline-offset-4 text-sm text-white/70 hover:text-white duration-300"
          >
            More information
          </a>
        </div>
      </div>

      <div className="order-1 md:order-2">
        <Lottie animationData={liquidityAnimation} loop={true} />
      </div>
    </div>
  );
}
