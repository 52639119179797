import { ApexOptions } from 'apexcharts';
import { IVault, TemporaryVault } from '../types';
import { abbreviateNumber } from '../utils/n-formatter';

export function getLineChartOptions(seriesInput: any, vault: IVault | TemporaryVault): ApexOptions {
  return {
    colors: ['#ffa760', '#f3f2f2'],
    chart: {
      type: 'area',
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    /*   markers: {
    size: [0, 0, 5, 5],
    strokeWidth: 0,
  }, */
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: [0.3, 0.2],
        opacityTo: [0.4, 0.3],
      },
    },
    legend: {
      show: false,
      horizontalAlign: 'left',
      fontSize: '14px',
      labels: {
        colors: ['#ffa760', '#f3f2f2', '#00B7A1', '#F24D4D'],
      },
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: function ({ series, dataPointIndex }) {
        return `
        <div class="bg-transparent p-1">
        <div class="bg-white text-[#121312] p-2 m-1 text-[14px] leading-[18px] flex flex-col gap-1 rounded-[12px]">
        <div class="flex items-center gap-1 justify-between">
        $${
          abbreviateNumber(series[0][dataPointIndex], 2) +
          ' (' +
          ((series[0][dataPointIndex] * 100) / (series[0][dataPointIndex] + series[1][dataPointIndex])).toFixed(2) +
          '%)'
        }
        <img src=${
          vault.token0LogoUrl
        } alt={vault.symbol0} class="rounded-full max-w-[16px] max-h-[16px] align-middle -mt-[2px]" />
        </div>
        <div class="flex items-center gap-1 justify-between">
        <div>
        $${
          abbreviateNumber(series[1][dataPointIndex], 2) +
          ' (' +
          ((series[1][dataPointIndex] * 100) / (series[0][dataPointIndex] + series[1][dataPointIndex])).toFixed(2) +
          '%)'
        }
        </div>
        <img src=${
          vault.token1LogoUrl
        } alt={vault.symbol1} class="rounded-full max-w-[16px] max-h-[16px] align-middle -mt-[2px]" />

        </div>
        <div>
    
      </div>
  
   
        </div>
        </div>
        `;
      },
      fillSeriesColor: false,
      // theme: 'light',
      style: {
        fontSize: '12px',
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: false,
        format: 'dd MMM yyyy HH:mm',
        formatter: undefined,
      },
      y: {
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },

      marker: {
        show: false,
      },
      items: {
        display: 'flex',
      },
      fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 10,
        offsetY: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [1, 1, 0, 0],
      curve: 'smooth',
      colors: ['#ffa760', '#f3f2f2'],
    },
    xaxis: {
      labels: {
        rotate: 0,
        style: {
          colors: '#605E67',
        },
      },
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
      type: 'datetime',
      axisBorder: {
        show: false,
      },
      tickAmount: 6,
    },
    yaxis: {
      labels: {
        style: {
          colors: '#605E67',
        },
        formatter: function (val) {
          const decimals = val > 1000 ? 0 : 2;
          return '$ ' + abbreviateNumber(val, decimals);
        },
      },
    },
    grid: {
      borderColor: ' rgba(41, 40, 45, 1)',
    },
    series: seriesInput,
  };
}

export function getPerformanceLineChartOptions(seriesInput: any): ApexOptions {
  return {
    colors: ['#ffa760', '#f3f2f2'],
    chart: {
      type: 'area',
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    /*   markers: {
    size: [0, 0, 5, 5],
    strokeWidth: 0,
  }, */
    fill: {
      type: 'gradient',
      gradient: {
        opacityFrom: [0.3, 0.2],
        opacityTo: [0.4, 0.3],
      },
    },
    legend: {
      show: false,
      horizontalAlign: 'left',
      fontSize: '14px',
      labels: {
        colors: ['#ffa760', '#f3f2f2', '#00B7A1', '#F24D4D'],
      },
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: function ({ dataPointIndex }) {
        return `
        <div class="bg-transparent p-1">
        <div class="bg-white text-[#121312] p-2 m-1 text-[14px] leading-[18px] flex flex-col gap-1 rounded-[12px]">
        <div class="flex items-center gap-1 justify-between">
        Strategy
        ${abbreviateNumber(seriesInput[0].data[dataPointIndex][1], 1)}%
       
        </div>
        <div class="flex items-center gap-1 justify-between">
        <div>
        HODL
        ${abbreviateNumber(seriesInput[1].data[dataPointIndex][1], 1)}%
        
        </div>
        <div>
    
        </div>
   

        </div>
        <div>
    
      </div>
  
   
        </div>
        </div>
        `;
      },
      fillSeriesColor: false,
      // theme: 'light',
      style: {
        fontSize: '12px',
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: false,
        format: 'dd MMM yyyy HH:mm',
        formatter: undefined,
      },
      y: {
        formatter: undefined,
        title: {
          formatter: (seriesName) => seriesName,
        },
      },

      marker: {
        show: false,
      },
      items: {
        display: 'flex',
      },
      fixed: {
        enabled: false,
        position: 'topRight',
        offsetX: 10,
        offsetY: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [1, 1, 0, 0],
      curve: 'smooth',
      colors: ['#ffa760', '#f3f2f2'],
    },
    xaxis: {
      labels: {
        rotate: 0,
        style: {
          colors: '#605E67',
        },
      },
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: false,
      },
      type: 'datetime',
      axisBorder: {
        show: false,
      },
      tickAmount: 6,
    },
    yaxis: {
      min: -15,
      max: 15,
      labels: {
        style: {
          colors: '#605E67',
        },
        formatter: function (val) {
          return val.toFixed(0) + '%';
        },
      },
    },
    grid: {
      borderColor: ' rgba(41, 40, 45, 1)',
    },
    series: seriesInput,
  };
}
