import ReactApexChart from 'react-apexcharts';
import { ConfigDetailsResponse } from '../../../../types/create-vault';
import { MAX_CAP_CHART, getPriceImpactChartOptions } from '../../../../utils/charts';
import { capNumberMax, capNumberMin } from '../../../../utils/math';
import { abbreviateNumber, numberWithCommas, shortenNumber } from '../../../../utils/n-formatter';
import { Image } from '../../../Image';

interface PriceImpactChartProps {
  configDetails: ConfigDetailsResponse;
  totalAmountUsd: number;
  selectedDataPoint: number;
  setSelectedDataPoint: (dataPoint: number) => void;
}

function PriceImpactChart({
  configDetails,
  totalAmountUsd,
  selectedDataPoint,
  setSelectedDataPoint,
}: PriceImpactChartProps) {
  if (
    configDetails.priceImpacts.every(
      ([, impactNegative, impactPositive]) => impactNegative === null && impactPositive === null,
    )
  ) {
    return (
      <div className="relative">
        <Image src="/assets/images/price-impact-chart-blurred.png" alt="price impact chart dummy" className="" />
        <div className="absolute top-0 w-full h-full flex items-center justify-center mb-10 gap-2 duration-300 rounded-lg py-2 text-vault-orange-2">
          {totalAmountUsd > 2500 ? (
            <span> Price impact chart is not available for this configuration.</span>
          ) : (
            <span> Price impact chart is not available for total values less than $2,500. </span>
          )}
        </div>
      </div>
    );
  }

  const options = getPriceImpactChartOptions(configDetails.priceImpacts, setSelectedDataPoint);

  const series = [
    {
      data: configDetails?.priceImpacts.map(([amount, impactNegative, impactPositive]) => ({
        x: `$${abbreviateNumber(amount)}`,
        y: [capNumberMin(impactNegative || 0, -MAX_CAP_CHART), capNumberMax(impactPositive || 0, MAX_CAP_CHART)],
      })),
    },
  ];

  return (
    <>
      {' '}
      {/* @ts-ignore */}
      <ReactApexChart options={options} series={series} height={380} type="rangeBar" />
      {configDetails && (
        <div className="ml-5 mt-3 text-sm">
          If your vault contains a total of{' '}
          <span className="text-vault-orange">
            ${totalAmountUsd ? numberWithCommas(shortenNumber(totalAmountUsd)) : ''}
          </span>{' '}
          and someone swaps{' '}
          <span className="text-vault-orange">
            ${numberWithCommas(shortenNumber(configDetails?.priceImpacts[selectedDataPoint][0]))}
          </span>{' '}
          the price can move up by{' '}
          <span className="text-emerald-400">{configDetails.priceImpacts[selectedDataPoint][2]?.toFixed(1)}%</span> and
          down by <span className="text-red-400">{configDetails.priceImpacts[selectedDataPoint][1]?.toFixed(1)}%</span>.
        </div>
      )}
    </>
  );
}

export default PriceImpactChart;
