import { useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import CheckIcon from '/assets/images/icon-check-2.svg';

import { useMutation } from '@tanstack/react-query';
import { track } from '@vercel/analytics/react';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { parseUnits, zeroAddress } from 'viem';
import { useAccount } from 'wagmi';
import { PoolCreationStatus, getMinimumCardinalityByChainId } from '../../../constants';
import { useBalances } from '../../../hooks/useBalances';
import { useCheckPoolExistence } from '../../../hooks/useCheckPoolExistence';
import { usePoolSlot0 } from '../../../hooks/usePoolSlot0';
import { watchVaultCreationEvent } from '../../../hooks/useWatchVaultCreationEvent';
import { SetupVaultEvent, SetupVaultEventSchema, TemporaryVault } from '../../../types';
import { StrategySchema, VaultCreationParams, getInitialVaultCreationParams } from '../../../types/create-vault';
import { roundDownToNDecimals } from '../../../utils/math';
import { getChainidFromNetworkName, numberWithCommas, shortenNumber } from '../../../utils/n-formatter';
import { Token } from '../../../utils/tokens';
import {
  getUsdcAddressByChainId,
  getWethAddressByChainId,
  isUsdcTokenAddress,
  sortTokensByHexValue,
} from '../../../utils/vault-creation';
import PrimaryButton from '../../elements/buttons/PrimaryButton';
import Cube from '../../elements/spinner/Cube';
import Toggle from '../../elements/toggle/Toggle';
import FormSection from '../FormSection';
import BaseTokenSelection from './components/BaseTokenSelection';
import CreatePoolButton from './components/CreatePoolButton';
import CreateVaultTransaction from './components/CreateVaultTransaction';
import FeeTierSelection from './components/FeeTierSelection';
import GasTankForm from './components/GasTankForm';
import NavigationButtons from './components/NavigationButtons';
import NetworkSelection from './components/NetworkSelection';
import PriceConfirmModal from './components/PriceConfirmModal';
import ReviewVault from './components/ReviewVault';
import Simulator from './components/Simulator';
import StrategyEditor from './components/StrategyEditor';
import TokenAmountsForm from './components/TokenAmountsForm';
import TokenSelector from './components/TokenSelector';
import VaultOwnerInput from './components/VaultOwnerInput';
import PriceChecker from './components/price-checker/PriceChecker';

interface VaultCreateFormProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

export default function VaultCreateForm({ currentStep, setCurrentStep }: VaultCreateFormProps) {
  const { address } = useAccount();

  const defaultFormValues = useMemo(() => getInitialVaultCreationParams(10, address || zeroAddress), [address]);

  const {
    register,
    setError,
    clearErrors,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
    control,
    reset,
  } = useForm<VaultCreationParams>({
    // resolver: zodResolver(VaultCreationParamsSchema), // todo: error handling not working
    defaultValues: defaultFormValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const { watch: watchOwnTokenPriceUsd, setValue: setOwnTokenPriceUsd } = useForm<{
    ownTokenPriceUsd: number | null;
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    control: controlCustomOwnTokenPriceUsd,
    watch: watchCustomOwnTokenPriceUsd,
    setValue: setCustomOwnTokenPriceUsd,
  } = useForm<{
    customOwnTokenPriceUsd: number | null;
  }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const ownTokenPriceUsd = watchOwnTokenPriceUsd('ownTokenPriceUsd');
  const customOwnTokenPriceUsd = watchCustomOwnTokenPriceUsd('customOwnTokenPriceUsd');

  const baseTokenAddress = watch('baseTokenAddress');
  const ownTokenAddress = watch('ownTokenAddress');

  useEffect(() => {
    if (ownTokenAddress === '') {
      setSelectedToken(null);
    }
  }, [ownTokenAddress]);

  const network = watch('network');
  const feeTier = watch('feeTier');
  const strategy = watch('strategy');
  const ownTokenAmount = watch('ownTokenAmount');
  const baseTokenAmount = watch('baseTokenAmount');

  const allowCustomToken = import.meta.env.VITE_ALLOW_CUSTOM_TOKENS === 'true';

  const ownTokenAmountNumber = useMemo(() => Number(ownTokenAmount), [ownTokenAmount]);
  const baseTokenAmountNumber = useMemo(() => Number(baseTokenAmount), [baseTokenAmount]);

  const gasTankAmount = watch('gasTankAmount');
  const ownerAddress = watch('ownerAddress');
  const chainId = useMemo(() => getChainidFromNetworkName(network), [network]);

  const [selectedToken, setSelectedToken] = useState<Token | null>(null);

  const [customTokenConfirmed, setCustomTokenConfirmed] = useState(false);

  const [isPriceConfirmModalOpen, setIsPriceConfirmModalOpen] = useState(false);
  const [lastPriceConfirmed, setLastPriceConfirmed] = useState(0);

  const [tokenRatio, setTokenRatio] = useState<number>(0.5);

  const [totalValueUsd, setTotalValueUsd] = useState<string>('0');

  const wethTokenAdress = getWethAddressByChainId(chainId);
  const usdcTokenAddress = getUsdcAddressByChainId(chainId);

  const ownTokenAmountBn = useMemo(() => {
    try {
      const decimals = selectedToken?.decimals || 18;

      return parseUnits(roundDownToNDecimals(ownTokenAmountNumber || 0, decimals).toString(), decimals);
    } catch (error) {
      return BigInt(0);
    }
  }, [ownTokenAmountNumber, selectedToken]);

  const baseTokenAmountBn = useMemo(() => {
    try {
      const decimals = baseTokenAddress === usdcTokenAddress ? 6 : 18;
      return parseUnits(roundDownToNDecimals(baseTokenAmountNumber || 0, decimals).toString(), decimals);
    } catch (error) {
      return BigInt(0);
    }
  }, [usdcTokenAddress, baseTokenAddress, baseTokenAmountNumber]);

  const gasTankAmountBn = useMemo(() => {
    try {
      return parseUnits(roundDownToNDecimals(gasTankAmount || 0, 18).toString(), 18); // todo: for now all network tokens have 18 decimals, in the future we can use useBalance to get the decimals
    } catch (error) {
      return BigInt(0);
    }
  }, [gasTankAmount]);

  const baseTokenLogoUrl =
    baseTokenAddress === usdcTokenAddress
      ? 'https://raw.githubusercontent.com/compound-finance/token-list/master/assets/asset_USDC.svg'
      : 'https://assets-stage.dex.guru/icons/0x82af49447d8a07e3bd95bd0d56f35241523fbab1-arbitrum.png';

  const baseTokenSymbol = baseTokenAddress === usdcTokenAddress ? 'USDC' : 'WETH';

  useEffect(() => {
    const isCurrentTokenUsdc = isUsdcTokenAddress(baseTokenAddress);

    const newBaseTokenAddress = isCurrentTokenUsdc
      ? getUsdcAddressByChainId(getChainidFromNetworkName(network))
      : getWethAddressByChainId(getChainidFromNetworkName(network));

    setValue('baseTokenAddress', newBaseTokenAddress);
  }, [network, baseTokenAddress, setValue]);

  // validate strategy json whenever it changes
  // TODO: USE ZOD VALIDATOR FOR THIS
  useEffect(() => {
    try {
      JSON.parse(strategy);
      clearErrors('strategy');
    } catch (error) {
      setError('strategy', {
        type: 'manual',
        message: 'This is not a valid JSON',
      });
    }
  }, [strategy, clearErrors, setError]);

  const { poolAddress: existingPool } = useCheckPoolExistence({
    token0: ownTokenAddress as `0x{string}`,
    token1: baseTokenAddress as `0x{string}`,
    feeTier,
    chainId,
  });

  const { slot } = usePoolSlot0(existingPool || '', chainId);

  const [manualSqrtPriceX96, setManualSqrtPriceX96] = useState<bigint | undefined>(undefined);

  const { sqrtPriceX96, observationCardinalityNext } = slot || {};

  const isCardinalityTooLow = (observationCardinalityNext || 0) < getMinimumCardinalityByChainId(chainId);

  const poolCreationStatus = useMemo(() => {
    let status: PoolCreationStatus = PoolCreationStatus.NEW;

    if (existingPool) {
      status = PoolCreationStatus.POOL_CREATED;
    }

    if (existingPool && sqrtPriceX96) {
      status = PoolCreationStatus.POOL_INITIALIZED;
    }

    if (!isCardinalityTooLow) {
      status = PoolCreationStatus.CARDINALITY_INCREASED;
    }

    return status;
  }, [existingPool, sqrtPriceX96, isCardinalityTooLow]);

  const isContinueButtonEnabled = useMemo(() => {
    switch (currentStep) {
      case 0:
        return !errors.ownerAddress && (ownTokenPriceUsd || customOwnTokenPriceUsd) && manualSqrtPriceX96;
      case 1:
        return (
          !errors.strategy &&
          !errors.baseTokenAmount &&
          !errors.ownTokenAmount &&
          !(baseTokenAmountNumber === 0 && ownTokenAmountNumber === 0)
        );
      default:
        return false;
    }
  }, [
    currentStep,
    customOwnTokenPriceUsd,
    errors.strategy,
    errors.ownerAddress,
    baseTokenAmountNumber,
    ownTokenAmountNumber,
    errors.ownTokenAmount,
    errors.baseTokenAmount,
    manualSqrtPriceX96,
    ownTokenPriceUsd,
  ]);

  const getToken0Decimals = () =>
    baseTokenAddress === token0 ? (baseTokenAddress === usdcTokenAddress ? 6 : 18) : selectedToken?.decimals || 18;

  const getToken1Decimals = () =>
    baseTokenAddress === token0 ? selectedToken?.decimals || 18 : baseTokenAddress === usdcTokenAddress ? 6 : 18;

  function createTemporaryVaultInSessionStorage() {
    const isToken0BaseToken = baseTokenAddress === token0;

    const vault: TemporaryVault = {
      id: zeroAddress,
      token0,
      token1,
      network,
      name: `${baseTokenSymbol}/${selectedToken?.symbol}`,
      symbol0: isToken0BaseToken ? baseTokenSymbol : selectedToken?.symbol || '',
      symbol1: isToken0BaseToken ? selectedToken?.symbol || '' : baseTokenSymbol,
      price0: price0 || 0,
      price1: price1 || 0,
      token0LogoUrl: isToken0BaseToken ? baseTokenLogoUrl : selectedToken?.logoURI || '',
      token1LogoUrl: isToken0BaseToken ? selectedToken?.logoURI || '' : baseTokenLogoUrl,
      version: 'V2',
      manager: ownerAddress,
      owner: ownerAddress,
      creator: ownerAddress,
      isClosed: false,
      gasTank: gasTankAmount || 0,
      chainId,
      totalSupply: '1000000000000000000',
      token0Decimals: getToken0Decimals(),
      token1Decimals: getToken1Decimals(),
      tvl: totalUsdAmount || 0,
      amount0: isToken0BaseToken ? baseTokenAmountNumber || 0 : ownTokenAmountNumber || 0,
      amount1: isToken0BaseToken ? ownTokenAmountNumber || 0 : baseTokenAmountNumber || 0,
      feeTier,
      strategy: parsedStrategy,
    };
    sessionStorage.setItem('temporary-vault', JSON.stringify(vault));
  }

  function onVaultCreated() {
    setCurrentStep(3);
  }

  function parseStrategy(strategy: string) {
    try {
      const parsedStrategy = StrategySchema.safeParse(JSON.parse(strategy));

      if (parsedStrategy.success) {
        return parsedStrategy.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  const parsedStrategy = useMemo(() => parseStrategy(strategy), [strategy]);

  const [token0, token1] = useMemo(
    () => sortTokensByHexValue(ownTokenAddress, baseTokenAddress),
    [ownTokenAddress, baseTokenAddress],
  );

  const [price0, setPrice0] = useState<number | null>(null);
  const [price1, setPrice1] = useState<number | null>(null);

  const ownAmountUsd = useMemo(() => {
    try {
      if (token0 === ownTokenAddress) {
        return (price0 as number) * ownTokenAmountNumber;
      }
      return (price1 as number) * ownTokenAmountNumber;
    } catch (error) {
      return null;
    }
  }, [price0, price1, ownTokenAmountNumber, token0, ownTokenAddress]);

  const baseAmountUsd = useMemo(() => {
    try {
      if (token0 === ownTokenAddress) {
        return (price1 as number) * baseTokenAmountNumber;
      }
      return (price0 as number) * baseTokenAmountNumber;
    } catch (error) {
      return null;
    }
  }, [price0, price1, baseTokenAmountNumber, token0, ownTokenAddress]);

  const totalUsdAmount = useMemo(() => {
    return (ownAmountUsd || 0) + (baseAmountUsd || 0);
  }, [ownAmountUsd, baseAmountUsd]);

  function setNewTokenRatio(newTokenRatio: number) {
    if (!isNaN(newTokenRatio)) {
      setTokenRatio(newTokenRatio);
      const ownTokenAmountUsd = totalUsdAmount * newTokenRatio;
      const baseTokenAmountUsd = totalUsdAmount * (1 - newTokenRatio);

      if (typeof price0 === 'number' && typeof price1 === 'number') {
        const newBaseTokenAmount =
          token0 === baseTokenAddress ? baseTokenAmountUsd / price0 : baseTokenAmountUsd / price1;
        const newOwnTokenAmount = token0 === ownTokenAddress ? ownTokenAmountUsd / price0 : ownTokenAmountUsd / price1;
        setValue('baseTokenAmount', newBaseTokenAmount.toString(), { shouldValidate: true });
        setValue('ownTokenAmount', newOwnTokenAmount.toString(), { shouldValidate: true });
      }
    }
  }

  function setNewTotalValueUsd(newTotalValueUsd: string) {
    setTotalValueUsd(newTotalValueUsd);

    const newTotalValueUsdNumber = Number(newTotalValueUsd);

    if (typeof price0 === 'number' && typeof price1 === 'number') {
      const newBaseTokenAmount =
        token0 === baseTokenAddress
          ? (newTotalValueUsdNumber * (1 - tokenRatio)) / price0
          : (newTotalValueUsdNumber * (1 - tokenRatio)) / price1;

      const newOwnTokenAmount =
        token0 === ownTokenAddress
          ? (newTotalValueUsdNumber * tokenRatio) / price0
          : (newTotalValueUsdNumber * tokenRatio) / price1;

      setValue('baseTokenAmount', newBaseTokenAmount.toString(), { shouldValidate: true });
      setValue('ownTokenAmount', newOwnTokenAmount.toString(), { shouldValidate: true });
    }
  }

  function setNewOwnTokenAmount(newOwnTokenAmount: string) {
    setValue('ownTokenAmount', newOwnTokenAmount);

    if (typeof price0 === 'number' && typeof price1 === 'number') {
      const ownAmountUsd = Number(newOwnTokenAmount) * (token0 === ownTokenAddress ? price0 : price1);
      const baseAmountUsd = Number(baseTokenAmount) * (token0 === baseTokenAddress ? price0 : price1);
      const totalValueUsd = ownAmountUsd + baseAmountUsd;

      if (!totalValueUsd) {
        setTokenRatio(0.5);
        setTotalValueUsd('0');
        return;
      }

      const roundedTotalValueUsd = roundDownToNDecimals(totalValueUsd, 2);

      const roundedTokenRatio = roundDownToNDecimals(ownAmountUsd / totalValueUsd, 2);

      setTotalValueUsd(roundedTotalValueUsd.toString());

      setTokenRatio(roundedTokenRatio);
    }
  }

  function setNewBaseTokenAmount(newBaseTokenAmount: string) {
    setValue('baseTokenAmount', newBaseTokenAmount);

    if (typeof price0 === 'number' && typeof price1 === 'number') {
      const ownAmountUsd = Number(ownTokenAmount) * (token0 === ownTokenAddress ? price0 : price1);
      const baseAmountUsd = Number(newBaseTokenAmount) * (token0 === baseTokenAddress ? price0 : price1);
      const totalValueUsd = ownAmountUsd + baseAmountUsd;

      if (!totalValueUsd) {
        setTokenRatio(0.5);
        setTotalValueUsd('0');
        return;
      }

      const roundedTotalValueUsd = roundDownToNDecimals(totalValueUsd, 2);
      const roundedTokenRatio = roundDownToNDecimals(ownAmountUsd / totalValueUsd, 2);

      setTotalValueUsd(roundedTotalValueUsd.toString());

      setTokenRatio(roundedTokenRatio);
    }
  }

  const navigate = useNavigate();

  const [parsedEvent, setParsedEvent] = useState<SetupVaultEvent>([]);

  const { mutate: triggerFetchNewVault } = useMutation({
    mutationFn: async ({ vaultId, chainId }: { vaultId: string; chainId: number }) => {
      const url = import.meta.env.VITE_BACKEND_URL;
      const res = await axios.post<{ success: boolean }>(`${url}/api/vault/trigger/add-v2-vault`, {
        vaultId,
        chainId,
      });
      return res.data?.success;
    },
    retry: 10,
    retryDelay: () => 4000,
    onSuccess: () => {
      navigate('/my-palm-vaults?vault-creation=true');
    },
    onError: (error: unknown) => {
      // if backend fails to fetch new vaults, we use session storage to store the temporary vault
      console.error('error in triggerFetchNewVaults', error);
      sessionStorage.setItem('temporary-vault-id', parsedEvent[0].args.vault);
      createTemporaryVaultInSessionStorage();
      setTimeout(() => {
        navigate('/my-palm-vaults?temporary-vault=true&vault-creation=true');
      }, 5000);
    },
  });

  function listenForVaultCreation() {
    watchVaultCreationEvent({
      chainId: chainId,
      onVaultCreated: (event) => {
        const parsedEvent = SetupVaultEventSchema.safeParse(event);
        if (parsedEvent.success) {
          const vault = parsedEvent.data[0].args.vault;

          track('vault created', {
            vault: vault,
            type: 'event',
            feature: 'vault creation',
          });
          setCurrentStep(3);
          triggerFetchNewVault({ vaultId: vault.toLowerCase(), chainId });
          setParsedEvent(parsedEvent.data);
        }
      },
    });
  }

  const { balanceBaseToken, balanceOwnToken, isLoadingBalanceBaseToken, isLoadingBalanceOwnToken } = useBalances({
    ownTokenAddress,
    baseTokenAddress,
    chainId,
    address: address || zeroAddress,
  });

  const initializeTokenAmounts = () => {
    const numberBalanceBaseToken = Number(balanceBaseToken);
    const numberBalanceOwnToken = Number(balanceOwnToken);

    if (!isNaN(numberBalanceBaseToken) && !isNaN(numberBalanceOwnToken) && price0 && price1) {
      if (numberBalanceBaseToken === 0 || numberBalanceOwnToken === 0) {
        setNewTotalValueUsd('100000');
        return;
      }

      const ownTokenAmountUsd = numberBalanceOwnToken * (token0 === ownTokenAddress ? price0 : price1);
      const baseTokenAmountUsd = numberBalanceBaseToken * (token0 === baseTokenAddress ? price0 : price1);

      if (ownTokenAmountUsd < baseTokenAmountUsd) {
        setValue('ownTokenAmount', balanceOwnToken);

        setValue('baseTokenAmount', (ownTokenAmountUsd / (token0 === baseTokenAddress ? price0 : price1)).toString());
        setTotalValueUsd((roundDownToNDecimals(ownTokenAmountUsd, 2) * 2).toString());
      } else {
        setValue('baseTokenAmount', balanceBaseToken);

        setValue('ownTokenAmount', (baseTokenAmountUsd / (token0 === ownTokenAddress ? price0 : price1)).toString());
        setTotalValueUsd((roundDownToNDecimals(baseTokenAmountUsd, 2) * 2).toString());
      }
    }
    setTokenRatio(0.5);
  };

  const token0Symbol = baseTokenAddress === token0 ? baseTokenSymbol : selectedToken?.symbol || '';
  const token1Symbol = baseTokenAddress === token1 ? baseTokenSymbol : selectedToken?.symbol || '';

  const token0LogoUrl = baseTokenAddress === token0 ? baseTokenLogoUrl : selectedToken?.logoURI || '';
  const token1LogoUrl = baseTokenAddress === token1 ? baseTokenLogoUrl : selectedToken?.logoURI || '';

  return (
    <form>
      <div className="space-y-10 pb-12">
        <div>
          {currentStep === 0 && (
            <FormSection
              title="General information"
              description="In this step you need to fill in some general properties of your vault. For more explanation, click the question icons next to the titles."
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-y-8 gap-x-5 lg:gap-x-20">
                <NetworkSelection network={network} reset={reset} />
                <div className="flex flex-col md:flex-row md:items-center gap-8">
                  <BaseTokenSelection
                    baseTokenAddress={baseTokenAddress}
                    setValue={setValue}
                    wethTokenAdress={wethTokenAdress}
                    usdcTokenAddress={usdcTokenAddress}
                  />
                  <div className="flex flex-col gap-3 w-full">
                    <TokenSelector
                      currentChainId={chainId}
                      selectedToken={selectedToken}
                      onSelect={(token) => {
                        setValue('ownTokenAddress', token.address.toLowerCase() || zeroAddress);
                        setSelectedToken(token);
                      }}
                    />
                  </div>
                </div>
                <VaultOwnerInput
                  ownerAddress={ownerAddress}
                  register={register}
                  errors={errors.ownerAddress}
                  setValue={setValue}
                />
                <div className="flex flex-col md:flex-row items-stretch gap-8">
                  <FeeTierSelection feeTier={feeTier} setValue={setValue} />
                </div>
              </div>

              <div>
                {selectedToken && address && (
                  <div className="flex flex-col gap-8 w-fit">
                    <PriceChecker
                      ownTokenAddress={ownTokenAddress}
                      token0={token0}
                      baseTokenAddress={baseTokenAddress}
                      ownTokenDecimals={selectedToken?.decimals}
                      baseTokenDecimals={baseTokenAddress === usdcTokenAddress ? 6 : 18}
                      sqrtPriceX96={sqrtPriceX96}
                      setManualSqrtPriceX96={setManualSqrtPriceX96}
                      setOwnTokenPriceUsd={setOwnTokenPriceUsd}
                      setPrice0={setPrice0}
                      setPrice1={setPrice1}
                      customOwnTokenPriceControl={controlCustomOwnTokenPriceUsd}
                      customOwnTokenPriceUsd={customOwnTokenPriceUsd}
                      setCustomOwnTokenPriceUsd={setCustomOwnTokenPriceUsd}
                      ownTokenLogoUrl={selectedToken?.logoURI}
                      ownTokenSymbol={selectedToken?.symbol}
                    />
                    {!ownTokenPriceUsd && !allowCustomToken && (
                      <a href="https://qtbwfilnd5m.typeform.com/to/h3fluMwF" target="blank">
                        <PrimaryButton>Request Custom Token</PrimaryButton>
                      </a>
                    )}
                  </div>
                )}

                <PriceConfirmModal
                  isOpen={isPriceConfirmModalOpen}
                  closeModal={() => setIsPriceConfirmModalOpen(false)}
                  price={numberWithCommas(shortenNumber(ownTokenPriceUsd || customOwnTokenPriceUsd || 0))}
                  resetCustomTokenConfirmed={() => setCustomTokenConfirmed(false)}
                >
                  {customOwnTokenPriceUsd && (
                    <div className="flex items-center gap-3 my-3 text-xs">
                      <Toggle
                        id="custom-token-toggle"
                        checked={customTokenConfirmed}
                        onChange={() => setCustomTokenConfirmed((val) => !val)}
                      />
                      I understand that setting my custom token price can be risky
                    </div>
                  )}
                  {existingPool && manualSqrtPriceX96 && !isCardinalityTooLow ? (
                    <PrimaryButton
                      onClick={() => {
                        initializeTokenAmounts();
                        setLastPriceConfirmed(ownTokenPriceUsd || 0);
                        setIsPriceConfirmModalOpen(false);
                        setCurrentStep(1);
                      }}
                      disabled={!customTokenConfirmed && !!customOwnTokenPriceUsd}
                    >
                      Accept
                    </PrimaryButton>
                  ) : (
                    <CreatePoolButton
                      token0={ownTokenAddress}
                      token1={baseTokenAddress}
                      feeTier={feeTier}
                      chainId={chainId}
                      token0Decimals={selectedToken?.decimals || 18}
                      token1Decimals={isUsdcTokenAddress(baseTokenAddress) ? 6 : 18}
                      sqrtPriceX96={manualSqrtPriceX96}
                      status={poolCreationStatus}
                      disabled={!customTokenConfirmed && !!customOwnTokenPriceUsd}
                    />
                  )}
                </PriceConfirmModal>
              </div>
            </FormSection>
          )}

          {currentStep === 1 && (
            <div className="my-10 grid grid-cols-1 gap-8 md:grid md:grid-cols-2">
              <></>
              <StrategyEditor
                setValue={setValue}
                strategy={strategy}
                error={errors.strategy}
                ownTokenAddress={ownTokenAddress}
                baseTokenAddress={baseTokenAddress}
                tokenRatio={tokenRatio}
                feeTier={feeTier}
                chainId={chainId}
                // only use sqrtPriceX96 in config if we have no coingecko price
                sqrtPriceX96={ownTokenPriceUsd ? undefined : manualSqrtPriceX96}
              >
                <TokenAmountsForm
                  control={control}
                  baseTokenAmount={baseTokenAmount}
                  ownTokenAmount={ownTokenAmount}
                  ownTokenLogoUrl={selectedToken?.logoURI as string}
                  errors={errors}
                  baseTokenLogoUrl={baseTokenLogoUrl}
                  ownTokenSymbol={selectedToken?.symbol as string}
                  baseTokenSymbol={baseTokenSymbol}
                  trigger={trigger}
                  ownAmountUsd={ownAmountUsd}
                  baseAmountUsd={baseAmountUsd}
                  totalValueUsd={totalValueUsd}
                  setTotalValueUsd={setNewTotalValueUsd}
                  tokenRatio={tokenRatio}
                  setTokenRatio={setNewTokenRatio}
                  setNewOwnTokenAmount={setNewOwnTokenAmount}
                  setNewBaseTokenAmount={setNewBaseTokenAmount}
                  isLoadingBalanceBaseToken={isLoadingBalanceBaseToken}
                  isLoadingBalanceOwnToken={isLoadingBalanceOwnToken}
                  balanceBaseToken={balanceBaseToken}
                  balanceOwnToken={balanceOwnToken}
                />
              </StrategyEditor>

              {parsedStrategy && (
                <Simulator
                  ratio={tokenRatio}
                  token0={token0}
                  token1={token1}
                  wideRangeActiveAmountInToken1BPS={parsedStrategy.wideRangeActiveAmountInToken1BPS}
                  wideRangeAmount0Bps={parsedStrategy.wideRangeAmount0Bps}
                  wideRangeAmount1Bps={parsedStrategy.wideRangeAmount1Bps}
                  feeTier={feeTier}
                  setPrice0={setPrice0}
                  setPrice1={setPrice1}
                  totalAmountUsd={totalUsdAmount || 100000}
                  ownTokenAddress={ownTokenAddress}
                  token0LogoUrl={token0LogoUrl}
                  token1LogoUrl={token1LogoUrl}
                  token0Symbol={token0Symbol}
                  token1Symbol={token1Symbol}
                  sqrtPriceX96={manualSqrtPriceX96}
                  token0Decimals={getToken0Decimals()}
                  token1Decimals={getToken1Decimals()}
                />
              )}
            </div>
          )}

          {currentStep === 2 && (
            <div className="flex flex-col gap-8">
              <div className="flex flex-col md:flex-row gap-8">
                {selectedToken && (
                  <ReviewVault
                    ownerAddress={ownerAddress}
                    chainId={chainId}
                    ownToken={selectedToken}
                    baseTokenAddress={baseTokenAddress}
                    baseTokenLogoUrl={baseTokenLogoUrl}
                    baseTokenSymbol={baseTokenSymbol}
                    baseTokenAmount={baseTokenAmount}
                    ownTokenAmount={ownTokenAmount}
                    feeTier={getValues('feeTier')}
                    ratio={tokenRatio}
                  />
                )}
                <GasTankForm
                  chainId={chainId}
                  currentGasTankAmount={gasTankAmount}
                  errors={errors}
                  register={register}
                  trigger={trigger}
                  setValue={setValue}
                />
              </div>

              <CreateVaultTransaction
                amount0Bn={baseTokenAmountBn}
                amount1Bn={ownTokenAmountBn}
                symbol0={baseTokenSymbol}
                symbol1={selectedToken?.symbol as string}
                ownerAddress={ownerAddress as `0x{string}`}
                token0={baseTokenAddress as `0x{string}`}
                token1={ownTokenAddress as `0x{string}`}
                chainId={chainId}
                gasTankAmount={gasTankAmountBn}
                feeTier={feeTier}
                onSuccess={onVaultCreated}
                onStartTransaction={listenForVaultCreation}
                strategy={strategy}
              />
            </div>
          )}

          {currentStep === 3 && (
            <div className="flex flex-col items-center justify-center text-vault-light-gray text-[24px] my-20">
              <img src={CheckIcon} width="100px" alt="check icon" />
              <div className="mt-[48px]">You successfully created your vault!</div>

              <div className="my-5 flex flex-col items-center gap-3">
                <p className="text-sm mb-10">Please wait a few moments until your vault is ready...</p>
                <Cube />
              </div>
            </div>
          )}
        </div>

        <NavigationButtons
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          isContinueButtonEnabled={!!isContinueButtonEnabled}
          chainId={chainId}
          openConfirmationModal={() => setIsPriceConfirmModalOpen(true)}
          isPriceConfirmed={
            poolCreationStatus === PoolCreationStatus.CARDINALITY_INCREASED &&
            !!ownTokenPriceUsd &&
            ownTokenPriceUsd === lastPriceConfirmed
          }
        />
      </div>
    </form>
  );
}
