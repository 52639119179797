import { UseFormReset } from 'react-hook-form';
import { base, gnosis, sepolia } from 'viem/chains';
import { useAccount } from 'wagmi';
import { Explanation } from '../../../../types';
import { NetworkName, initialStrategy } from '../../../../types/create-vault';
import { getWethAddressByChainId } from '../../../../utils/vault-creation';
import Badge from '../../../elements/buttons/Badge';
import QuestionButton from '../../../layout/sidebar/QuestionButton';
import NetworkLogo from './NetworkLogo';

interface NetworkSelectionProps {
  network: string;
  reset: UseFormReset<any>;
}

function NetworkSelection({ network, reset }: NetworkSelectionProps) {
  const { address } = useAccount();

  function setNetworkAndResetForm(network: string) {
    reset({
      network: network as NetworkName,
      baseTokenAddress: getWethAddressByChainId(10),
      feeTier: 10000,
      ownerAddress: address,
      ownTokenAmount: 0,
      baseTokenAmount: 0,
      gasTankAmount: 0,
      strategy: initialStrategy,
      ownTokenAddress: '',
    });
  }
  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center gap-2">
        <h2 className="text-[16px] leading-[20px] trailing-[0.16px]">Select network</h2>
        <QuestionButton explanation={Explanation.NETWORK} title="Network explained" />
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
        <Badge isActive={network === 'ethereum'} onClick={() => setNetworkAndResetForm('ethereum')}>
          <NetworkLogo chainId={1} size={20} />
          <div>Ethereum</div>
        </Badge>
        <Badge isActive={network === 'polygon'} onClick={() => setNetworkAndResetForm('polygon')}>
          <NetworkLogo chainId={137} size={20} />
          <div>Polygon</div>
        </Badge>
        <Badge isActive={network === 'optimism'} onClick={() => setNetworkAndResetForm('optimism')}>
          <NetworkLogo chainId={10} size={20} />
          <div>Optimism</div>
        </Badge>
        <Badge isActive={network === 'arbitrum'} onClick={() => setNetworkAndResetForm('arbitrum')}>
          <NetworkLogo chainId={42161} size={20} />
          <div>Arbitrum</div>
        </Badge>
        <Badge isActive={network === 'base'} onClick={() => setNetworkAndResetForm('base')}>
          <NetworkLogo chainId={base.id} size={20} />
          <div>Base</div>
        </Badge>
        <Badge isActive={network === 'gnosis'} onClick={() => setNetworkAndResetForm('gnosis')}>
          <NetworkLogo chainId={gnosis.id} size={20} />
          <div>Gnosis</div>
        </Badge>
        {import.meta.env.DEV && (
          <Badge isActive={network === 'sepolia'} onClick={() => setNetworkAndResetForm('sepolia')}>
            <NetworkLogo chainId={sepolia.id} size={20} />
            <div>Sepolia</div>
          </Badge>
        )}
      </div>
    </div>
  );
}

export default NetworkSelection;
