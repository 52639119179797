import { Image } from '../../../Image';

function TvlExplanation() {
  const examples = [
    { name: 'Lido', amount: '$13.9bn' },
    { name: 'MakerDAO', amount: '$5.13bn' },
    { name: 'Aave', amount: '$4.53bn' },
    { name: 'JustLend', amount: '$3.36bn' },
    { name: 'Uniswap', amount: '$3.33bn' },
  ];

  return (
    <div>
      <h2 className="text-lg font-bold mb-1">What is Total Value Locked?</h2>
      <p>
        Total Value Locked (TVL) is a metric used to measure the total value of digital assets that are locked or staked
        in a particular decentralized finance (DeFi) platform or decentralized application (dApp).
      </p>
      <h2 className="text-lg font-bold mb-1 mt-5">How Is TVL Calculated?</h2>
      <p className="mb-2">
        The process generally involves adding up the digital assets currently locked in a specific DeFi protocol or
        smart contract. These assets could include cryptocurrencies, stablecoins, or other tokens that are being used as
        collateral for loans or provide the platform with liquidity.
      </p>
      <p>
        For example, if a DeFi platform is calculating its own total value locked and has $10 million worth of ETH, $5
        million worth of USDT, and $2 million worth of other ERC-20 tokens locked in its smart contract, then its TVL
        would be $17 million.
      </p>

      <div>
        <Image src="/assets/images/composition-example.png" alt="composition example" />
      </div>

      <h2 className="text-lg font-bold mb-1 mt-5">Examples</h2>

      <div className="inline-block min-w-full py-2 align-middle">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr className="divide-x divide-gray-200">
              <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-white sm:pl-0">
                Name
              </th>
              <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-white">
                Amount
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {examples.map((platform) => (
              <tr key={platform.name} className="divide-x divide-gray-200">
                <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-white sm:pl-0">
                  {platform.name}
                </td>
                <td className="whitespace-nowrap p-4 text-sm text-vault-orange">{platform.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p className="mb-2">
        The process generally involves adding up the digital assets currently locked in a specific DeFi protocol or
        smart contract. These assets could include cryptocurrencies, stablecoins, or other tokens that are being used as
        collateral for loans or provide the platform with liquidity.
      </p>
      <p>
        For example, if a DeFi platform is calculating its own total value locked and has $10 million worth of ETH, $5
        million worth of USDT, and $2 million worth of other ERC-20 tokens locked in its smart contract, then its TVL
        would be $17 million.
      </p>
      <p className="mb-2">
        The process generally involves adding up the digital assets currently locked in a specific DeFi protocol or
        smart contract. These assets could include cryptocurrencies, stablecoins, or other tokens that are being used as
        collateral for loans or provide the platform with liquidity.
      </p>
      <p>
        For example, if a DeFi platform is calculating its own total value locked and has $10 million worth of ETH, $5
        million worth of USDT, and $2 million worth of other ERC-20 tokens locked in its smart contract, then its TVL
        would be $17 million.
      </p>
    </div>
  );
}

export default TvlExplanation;
