import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import { memo, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  // eslint-disable-next-line import/named
  UsePrepareContractWriteConfig,
  erc20ABI,
  useAccount,
  useContractRead,
  useContractReads,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import { tokenProxyAbi } from '../../../abis/token-proxy';
import { ARRAKIS_V2_TERMS_ROUTER_ADDRESS } from '../../../constants';
import { DepositFormWrapperState, IVault, TemporaryVault, isIVault } from '../../../types';
import { formatBigNumber, parseTokenInputAmountToBigInt } from '../../../utils/format-big-number';
import './deposit.css';

import { track } from '@vercel/analytics/react';
import { Tooltip } from 'flowbite-react';
import 'react-toastify/dist/ReactToastify.css';
import { ArrakisV2TermsRouterAbi } from '../../../abis/ArrakisV2TermsRouter';
import { useConfetti } from '../../../context/ConfettiContext';
import { ExplorerDataType, getExplorerLink } from '../../../utils/get-explorer-link';
import { numberWithCommas } from '../../../utils/n-formatter';
import { bigintSelector } from '../../../utils/wagmi';
import { alertUser } from '../../elements/notify';
import { Spinner } from '../../elements/spinner';
import DepositFormWrapper from '../../hoc/deposit-form-wrapper';
import InputField from './components/InputField';

enum Status {
  APPROVE_0 = 'Approve_0',
  APPROVE_1 = 'Approve_1',
  DEPOSIT = 'Deposit',
}

interface DepositFormProps {
  vault: IVault | TemporaryVault;
  currentAmountUsd: number;
  isIncreaseLiquiditySuccess: boolean;
}

export interface IDepositFormInput {
  token0Input: number;
  token1Input: number;
}

function DepositFormPalm({ vault, currentAmountUsd, isIncreaseLiquiditySuccess }: DepositFormProps) {
  const { address, connector } = useAccount();

  const [status, setStatus] = useState<Status>(Status.APPROVE_0);
  const [depositFormWrapperState, setDepositFormWrapperState] = useState(DepositFormWrapperState.DEFAULT);
  const [isLoadingSafeWallet, setLoadingSafeWallet] = useState(false);
  const isSafeWallet = connector?.id === 'safe';

  // get user balances for both tokens
  const { data: balance0, isLoading: balance0Loading } = useContractRead({
    address: vault.token0 as '0x${string}',
    abi: tokenProxyAbi,
    functionName: 'balanceOf',
    args: [address],
    chainId: vault.chainId,
    select: bigintSelector,
  });

  const { data: balance1, isLoading: balance1Loading } = useContractRead({
    address: vault.token1 as '0x${string}',
    abi: tokenProxyAbi,
    functionName: 'balanceOf',
    args: [address],
    chainId: vault.chainId,
    select: bigintSelector,
  });

  const balance0Value = balance0 ? formatBigNumber(balance0 as bigint, vault.token0Decimals, 8) : '0';
  const balance1Value = balance1 ? formatBigNumber(balance1 as bigint, vault.token1Decimals, 8) : '0';

  // input fields are in token currency, this is the USD value
  const [token0InputUsd, setToken0InputUsd] = useState(0);
  const [token1InputUsd, setToken1InputUsd] = useState(0);

  // add liquidity params for the transaction need to be fetched
  const [addLiquidityArgsPalm, setAddLiquidityArgsPalm] = useState<any[]>([]);

  const { startConfettiAnimation } = useConfetti();

  // form
  const {
    register,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<IDepositFormInput>({
    defaultValues: {
      token0Input: 0,
      token1Input: 0,
    },
    mode: 'onChange',
  });

  const token0Input = watch('token0Input');
  const token1Input = watch('token1Input');

  const price0 = isIVault(vault) ? vault.price0 ?? vault.cmcPrice0 : vault.price0;
  const price1 = isIVault(vault) ? vault.price1 ?? vault.cmcPrice1 : vault.price1;

  // update usd value when user changes input
  useEffect(() => {
    const value0 = token0Input;
    setToken0InputUsd(price0 * (value0 || 0));
    const value1 = token1Input;
    setToken1InputUsd(price1 * (value1 || 0));
  }, [token0Input, token1Input]);

  // update input fields when user clicks max button
  function setMaxToken0() {
    setValue('token0Input', Number(balance0Value), { shouldDirty: true, shouldValidate: true });
  }

  function setMaxToken1() {
    setValue('token1Input', Number(balance1Value), { shouldDirty: true, shouldValidate: true });
  }

  // input values in token currency
  const parsedInp0 = useMemo(
    () => parseTokenInputAmountToBigInt(token0Input, vault.token0Decimals),

    [vault.token0Decimals, token0Input],
  );

  const parsedInp1 = useMemo(
    () => parseTokenInputAmountToBigInt(token1Input, vault.token1Decimals),

    [vault.token1Decimals, token1Input],
  );

  console.log('parsedInp0', parsedInp0);
  console.log('parsedInp1', parsedInp1);

  // allowances: check if user has already approved tokens

  const token0Contract = {
    address: vault.token0 as '0x${string}',
    abi: erc20ABI,
  };

  const token1Contract = {
    address: vault.token1 as '0x${string}',
    abi: erc20ABI,
  };

  const routerAddress = ARRAKIS_V2_TERMS_ROUTER_ADDRESS;

  const { data: allowances, isLoading: isLoadingApprovals } = useContractReads({
    contracts: [
      {
        ...token0Contract,
        functionName: 'allowance',
        args: [(address as any) ?? BigInt(0), routerAddress as '0x${string}'],
      },
      {
        ...token1Contract,
        functionName: 'allowance',
        args: [(address as any) ?? BigInt(0), routerAddress as '0x${string}'],
      },
    ],
    watch: true,
  });

  // alert user when success turns true
  useEffect(() => {
    if (isIncreaseLiquiditySuccess) {
      alertUser('success', 'Transaction successful!', '', '', isSafeWallet);
    }
  }, [isIncreaseLiquiditySuccess]);

  const token0Allowance = allowances?.[0].result;
  const token1Allowance = allowances?.[1].result;

  // approving tokens

  const addRecentTransaction = useAddRecentTransaction();

  const { config: approveToken0Config } = usePrepareContractWrite({
    ...token0Contract,
    functionName: 'approve',
    args: [routerAddress, parsedInp0],
    chainId: vault.chainId,
    value: BigInt(0) as any, // safe app bug: https://github.com/safe-global/safe-apps-sdk/issues/480
  });

  const { config: approveToken1Config } = usePrepareContractWrite({
    ...token1Contract,
    functionName: 'approve',
    args: [routerAddress, parsedInp1],
    value: BigInt(0) as any, // safe app bug: https://github.com/safe-global/safe-apps-sdk/issues/480
  });

  const {
    data: token0ApproveData,
    write: token0Approve,
    isLoading: isLoadingToken0Approve,
  } = useContractWrite({
    ...approveToken0Config,
    onError(error) {
      alertUser('error', error.message, '', '', isSafeWallet);
    },
  });

  const {
    data: token1ApproveData,
    write: token1Approve,
    isLoading: isLoadingToken1Approve,
  } = useContractWrite({
    ...approveToken1Config,
    onError(error) {
      alertUser('error', error.message, '', '', isSafeWallet);
    },
  });

  const { isLoading: isLoadingToken0ApproveAfter } = useWaitForTransaction({
    hash: token0ApproveData?.hash,
    onSettled(data, error) {
      if (error) {
        alertUser('error', error.message, '', '', isSafeWallet);
        return;
      }
      alertUser(
        'success',
        'Transaction successful!',
        getExplorerLink(vault.chainId || 137, token0ApproveData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
        isSafeWallet,
      );

      track('approve token 0 for deposit palm', {
        type: 'click',
        feature: 'vault deposit form palm',
        wallet: address as string,
        vault: vault.id,
      });

      addRecentTransaction({
        hash: token0ApproveData?.hash ?? '',
        description: `Approve ${token0Input} ${vault.symbol0}`,
      });
    },
  });

  const { isLoading: isLoadingToken1ApproveAfter } = useWaitForTransaction({
    hash: token1ApproveData?.hash,
    onSettled(data, error) {
      if (error) {
        alertUser('error', error.message, '', '', isSafeWallet);
        return;
      }
      alertUser(
        'success',
        'Transaction successful!',
        getExplorerLink(vault.chainId || 137, token1ApproveData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
        isSafeWallet,
      );

      track('approve token 1 for deposit palm', {
        type: 'click',
        feature: 'vault deposit form palm',
        wallet: address as string,
        vault: vault.id,
      });

      addRecentTransaction({
        hash: token1ApproveData?.hash ?? '',
        description: `Approve ${token1Input} ${vault.symbol1}`,
      });
    },
  });

  // Increase Liquidity

  function getDepositConfig() {
    const config: UsePrepareContractWriteConfig = {
      address: ARRAKIS_V2_TERMS_ROUTER_ADDRESS,
      chainId: vault.chainId,
      abi: ArrakisV2TermsRouterAbi as any,
      functionName: 'increaseLiquidity',
      enabled:
        (!!parsedInp0 || !!parsedInp1) &&
        (parsedInp0 > BigInt(0) || parsedInp1 > BigInt(0)) &&
        status === Status.DEPOSIT,
      // @ts-ignore
      args: addLiquidityArgsPalm,
      onError(error) {
        console.log('error', error);
      },
      value: BigInt(0) as any, // safe app bug: https://github.com/safe-global/safe-apps-sdk/issues/480
    };

    if (isSafeWallet) {
      config.gas = BigInt(5000000);
    }

    return config;
  }

  const { config: addLiquidityConfig } = usePrepareContractWrite(getDepositConfig());

  const {
    data: addLiquidityData,
    write: addLiquidity,
    isLoading: isAddLiquidityLoading,
  } = useContractWrite({
    ...(addLiquidityConfig as any),
    onError(error) {
      alertUser('error', error.message, '', '', isSafeWallet);
    },
  });

  const { isLoading: isAddLiquidityLoadingAfter } = useWaitForTransaction({
    hash: addLiquidityData?.hash,
    onSettled(data, error) {
      if (error) {
        alertUser('error', error.message, '', '', isSafeWallet);
        return;
      }
      startConfettiAnimation();

      alertUser(
        'success',
        'Liquidity added successful!',
        getExplorerLink(vault.chainId || 137, addLiquidityData?.hash || '', ExplorerDataType.TRANSACTION),
        'View on Block Explorer',
        isSafeWallet,
      );

      track('deposit palm', {
        type: 'click',
        feature: 'vault deposit form palm',
        wallet: address as string,
        vault: vault.id,
        value: token0InputUsd + token1InputUsd,
      });

      setDepositFormWrapperState(DepositFormWrapperState.DEPOSIT_SUCCESS);

      addRecentTransaction({
        hash: addLiquidityData?.hash ?? '',
        description: 'Increase Liquidity',
      });
    },
  });

  useEffect(() => {
    const oldStatus = status;
    let newStatus = Status.APPROVE_0;

    // at least one input needs to be > 0
    if (parsedInp0 === BigInt(0) && parsedInp1 === BigInt(0)) {
      return;
    }

    const tokenAllowance0 = (token0Allowance ?? BigInt(0)) as bigint;
    const tokenAllowance1 = (token1Allowance ?? BigInt(0)) as bigint;

    const input0Approved = tokenAllowance0 >= parsedInp0;
    const input1Approved = tokenAllowance1 >= parsedInp1;

    if (input0Approved) {
      newStatus = Status.APPROVE_1;
    }

    if (input1Approved && input0Approved && (parsedInp0 > BigInt(0) || parsedInp1 > BigInt(0))) {
      newStatus = Status.DEPOSIT;
    }

    setStatus(newStatus);

    if (oldStatus !== newStatus) {
      setLoadingSafeWallet(false);
    }
  }, [token0Allowance, token0InputUsd, token1InputUsd, token1Allowance, parsedInp0, parsedInp1]);

  // update addLiquidity params whenever needed
  useEffect(() => {
    const updateAddLiquidityParams = async () => {
      if (
        // @ts-ignore
        (token0Allowance ?? BigInt(0)) >= parsedInp0 &&
        // @ts-ignore
        (token1Allowance ?? BigInt(0)) >= parsedInp1 &&
        vault &&
        address &&
        !(token0Input <= 0 && token1Input <= 0)
      ) {
        const params = [
          {
            vault: vault.id,
            amount0: parsedInp0,
            amount1: parsedInp1,
          },
        ];
        setAddLiquidityArgsPalm(params);
      } else {
        setAddLiquidityArgsPalm([
          {
            vault: vault.id,
            amount0: BigInt(0),
            amount1: BigInt(0),
          },
        ]);
      }
    };
    updateAddLiquidityParams();
  }, [token0Allowance, token1Allowance, vault, address, parsedInp0, parsedInp1, token0Input, token1Input]);

  function onAddLiquidity() {
    if (isSafeWallet && addLiquidity) {
      addLiquidity();
      setDepositFormWrapperState(DepositFormWrapperState.SAFE_APP_DEPOSIT);
    } else {
      addLiquidity?.();
    }
  }

  function onApproveToken0() {
    if (isSafeWallet) {
      setLoadingSafeWallet(true);
    }
    token0Approve?.();
  }

  function onApproveToken1() {
    if (isSafeWallet) {
      setLoadingSafeWallet(true);
    }
    token1Approve?.();
  }

  return (
    <DepositFormWrapper
      isLoading={balance0Loading || balance1Loading}
      state={depositFormWrapperState}
      usdValue={token0InputUsd + token1InputUsd}
    >
      <form>
        <div className="flex flex-col">
          <div className="flex flex-col md:flex-row justify-between gap-2 relative">
            <div className="grow">
              <InputField
                vault={vault}
                balanceValue={balance0Value}
                tokenInputUsd={token0InputUsd}
                symbol={vault.symbol0.toUpperCase()}
                showError={!!errors.token0Input && isDirty}
                errorMessage={errors.token0Input?.message}
                tokenType="token0"
                register={register}
                setMaxToken={setMaxToken0}
                isZapin={false}
              />
            </div>
            <div className="grow">
              <InputField
                vault={vault}
                balanceValue={balance1Value}
                tokenInputUsd={token1InputUsd}
                symbol={vault.symbol1.toUpperCase()}
                showError={!!errors.token1Input && isDirty}
                errorMessage={errors.token1Input?.message}
                tokenType="token1"
                register={register}
                setMaxToken={setMaxToken1}
                isZapin={false}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-between">
            <div>
              <div className="text-xl font-semibold mt-5 mb-2 text-vault-light text-[24px]">
                Deposit amount: ${numberWithCommas((token0InputUsd + token1InputUsd).toFixed(2))}{' '}
              </div>
              <div className="text-xl font-semibold mb-3 text-vault-gray-hover text-[20px]">
                New position will be: $
                {numberWithCommas((token0InputUsd + token1InputUsd + currentAmountUsd).toFixed(2))}{' '}
              </div>
            </div>
            <div></div>
          </div>

          <div>
            {status === Status.APPROVE_0 && (
              <div className="mb-5 mt-5 flex gap-3 items-center">
                <button
                  type="button"
                  onClick={onApproveToken0}
                  className={`flex flex-row justify-center items-center rounded-lg h-[64px] p-2 grow ${
                    !errors.token0Input && isDirty
                      ? 'bg-gradient-to-r from-[#FFA760] to-[#F45020] text-vault-black-primary'
                      : 'bg-vault-gray-2 text-vault-gray'
                  }`}
                  disabled={!!errors.token0Input || !isDirty}
                >
                  <div className="flex items-center gap-3">
                    Approve {vault.symbol0?.toUpperCase()}{' '}
                    {token0Allowance !== undefined && (
                      <Tooltip
                        // @ts-ignore
                        content={`already approved ${formatBigNumber(token0Allowance, vault.token0Decimals, 10)}`}
                        placement="right"
                        animation="duration-1000"
                        className="bg-[#181818] text-vault-white text-center"
                      ></Tooltip>
                    )}
                  </div>

                  {(isLoadingApprovals ||
                    isLoadingToken0Approve ||
                    isLoadingToken0ApproveAfter ||
                    isLoadingSafeWallet) && (
                    <span className="mx-2">
                      <Spinner color="vault-white" />
                    </span>
                  )}
                </button>
                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px]"
                >
                  2
                </button>
                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px]"
                >
                  3
                </button>
              </div>
            )}
            {status === Status.APPROVE_1 && (
              <div className="mb-5 mt-5 flex gap-3 items-center">
                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px] flex justify-center items-center"
                >
                  <img src="../../../assets/images/icon-check-2.svg" alt="check icon" />
                </button>
                <button
                  type="button"
                  onClick={onApproveToken1}
                  className={`flex flex-row justify-center items-center rounded-lg h-[64px] p-2 grow ${
                    !errors.token1Input && isDirty
                      ? 'bg-gradient-to-r from-[#FFA760] to-[#F45020] text-vault-black-primary'
                      : 'bg-vault-gray-2 text-vault-gray'
                  }`}
                  disabled={!!errors.token1Input || !isDirty}
                >
                  <div className="flex items-center gap-3">
                    Approve {vault.symbol1?.toUpperCase()}{' '}
                    {token1Allowance !== undefined && (
                      <Tooltip
                        // @ts-ignore
                        content={`already approved ${formatBigNumber(token1Allowance, vault.token1Decimals, 10)}`}
                        placement="right"
                        animation="duration-1000"
                        className="bg-[#181818] text-vault-white text-center"
                      ></Tooltip>
                    )}
                  </div>

                  {(isLoadingApprovals ||
                    isLoadingToken1Approve ||
                    isLoadingToken1ApproveAfter ||
                    isLoadingSafeWallet) && (
                    <span className="mx-2">
                      <Spinner color="vault-white" />
                    </span>
                  )}
                </button>

                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px]"
                >
                  3
                </button>
              </div>
            )}
            {status === Status.DEPOSIT && (
              <div className="mb-5 mt-5 flex gap-3 items-center">
                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px] flex items-center justify-center"
                >
                  <img src="../../../assets/images/icon-check-2.svg" alt="check icon" />
                </button>

                <button
                  disabled
                  className="py-2 grow bg-vault-gray-2 rounded-[12px] text-vault-gray-hover text-[20px] max-w-[44px]  h-[64px] flex items-center justify-center"
                >
                  <img src="../../../assets/images/icon-check-2.svg" alt="check icon" />
                </button>
                {isIVault(vault) && vault.gauge ? (
                  <button
                    type="button"
                    disabled={!addLiquidity || isAddLiquidityLoading || isAddLiquidityLoadingAfter}
                    onClick={onAddLiquidity}
                    className="flex flex-row justify-center items-center bg-gradient-to-r from-[#FFA760] to-[#F45020] text-vault-black-primary h-12 rounded-[12px] h-[64px] p-2 grow"
                  >
                    <span> Add Liquidity & Stake </span>
                    {(isAddLiquidityLoading || isAddLiquidityLoadingAfter) && (
                      <span className="mx-2">
                        <Spinner color="vault-white" />
                      </span>
                    )}
                  </button>
                ) : (
                  <button
                    type="button"
                    disabled={!addLiquidity || isAddLiquidityLoading || isAddLiquidityLoadingAfter}
                    onClick={onAddLiquidity}
                    className="flex flex-row justify-center items-center bg-gradient-to-r from-[#FFA760] to-[#F45020] text-vault-black-primary h-12 rounded-[12px] h-[64px] p-2 grow"
                  >
                    <span> Add Liquidity </span>
                    {(isAddLiquidityLoading || isAddLiquidityLoadingAfter) && (
                      <span className="mx-2">
                        <Spinner color="vault-white" />
                      </span>
                    )}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </form>
    </DepositFormWrapper>
  );
}

export default memo(DepositFormPalm);
