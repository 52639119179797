import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { getOwnPalmVaults } from '../../../utils/api';

interface NavbarProps {
  activeItem: 'deprecated-vaults' | 'v2-vaults' | 'my-liquidity' | 'my-palm-vaults';
}

function Navbar({ activeItem }: NavbarProps) {
  const { address } = useAccount();

  const { data: palmVaults } = useQuery(['own-v2-vaults'], () => getOwnPalmVaults(address), {
    refetchInterval: 30000,
    refetchOnWindowFocus: false,
    enabled: !!address,
  });

  const temporaryVault = sessionStorage.getItem('temporary-vault') || null;
  const temporaryVaultId = sessionStorage.getItem('temporary-vault-id');
  const isTemporaryVault = !!temporaryVault && !!temporaryVaultId;

  return (
    <div className="flex items-center gap-4 overflow-x-scroll sm:overflow-hidden whitespace-nowrap z-10">
      <Link
        to="/v2-vaults"
        className={`z-10 ${
          activeItem === 'v2-vaults'
            ? 'text-vault-light text-[32px] tracking-[-0.02em] leading-[32px]'
            : 'text-vault-gray text-[20px] tracking-[-0.01em] underline underline-offset-4 hover:text-white duration-300'
        }`}
      >
        V2 Vaults
      </Link>
      <Link
        to="/vaults"
        className={`z-10 ${
          activeItem === 'deprecated-vaults'
            ? 'text-vault-light text-[32px] tracking-[-0.02em] leading-[32px]'
            : 'text-vault-gray text-[20px] tracking-[-0.01em] underline underline-offset-4 hover:text-white duration-300'
        }`}
      >
        V1 Vaults <span className="hidden sm:inline text-xs">(legacy)</span>
      </Link>
      {!!address && (
        <Link
          to="/my-liquidity"
          className={`z-10 ${
            activeItem === 'my-liquidity'
              ? 'text-vault-light text-[32px] tracking-[-0.02em] leading-[32px]'
              : 'text-vault-gray text-[20px] tracking-[-0.01em] underline underline-offset-4 hover:text-white duration-300'
          }`}
        >
          My Liquidity
        </Link>
      )}

      {((palmVaults && palmVaults.length > 0) || isTemporaryVault) && (
        <Link
          to="/my-palm-vaults"
          className={`z-10 ${
            activeItem === 'my-palm-vaults'
              ? 'text-vault-light text-[32px] tracking-[-0.02em] leading-[32px]'
              : 'text-vault-gray text-[20px] tracking-[-0.01em] underline underline-offset-4 hover:text-white duration-300'
          }`}
        >
          My Palm Vaults
        </Link>
      )}
    </div>
  );
}

export default Navbar;
