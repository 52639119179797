import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import { z } from 'zod';
import { getNetworkNameFromChainId } from '../utils/n-formatter';
import { getWethAddressByChainId } from '../utils/vault-creation';

export const VaultCreationParamsSchema = z.object({
  ownTokenAddress: z.string(),
  baseTokenAddress: z.string(),
  network: z.enum(['ethereum', 'polygon', 'optimism', 'arbitrum', 'base', 'gnosis', 'sepolia', 'Unknown']),
  feeTier: z.number(),
  ownerAddress: z.string(),
  ownTokenAmount: z.string(),
  baseTokenAmount: z.string(),
  gasTankAmount: z.number(),
  strategy: z.string(),
});

export type VaultCreationParams = z.infer<typeof VaultCreationParamsSchema>;

export const StrategySchema = z.object({
  apiData: z.nullable(z.unknown()),
  assetIsTokenZero: z.boolean(),
  feeTier: z.number(),
  limitOrderMaxDistanceTicks: z.number(),
  limitOrderToBPS: z.number(),
  maxGasPrice: z.number(),
  maxLimitOrderBPS: z.number(),
  maxTwapDeviation: z.number(),
  minDeviationBPS: z.number(),
  skewLimitBPS: z.number(),
  strategy: z.string(),
  twapDuration: z.number(),
  version: z.number(),
  wideRangeActiveAmountInToken1BPS: z.number(),
  wideRangeAmount0Bps: z.number(),
  wideRangeAmount1Bps: z.number(),
  initialSqrtPriceX96: z.optional(z.string()),
  initialToleranceBPS: z.optional(z.number()),
  /*  minTick: z.number(),
  maxTick: z.number(),
  feeTiers: z.array(z.number()), */
  /*   midAllocationBps: z.number(),
  assetAllocationBps: z.number(),
  baseAllocationBps: z.number(),
  rangeSize: z.number(),
  assetRebalanceThreshold: z.number(),
  baseRebalanceThreshold: z.number(),
  baseMaxSwapAmount: z.string(),
  assetMaxSwapAmount: z.string(),
  baseMinVwapAmount: z.string(),
  assetMinVwapAmount: z.string(),
  baseMinRebalanceAmount: z.string(),
  assetMinRebalanceAmount: z.string(),
  coolDownPeriod: z.number(), */
});

export type Strategy = z.infer<typeof StrategySchema>;

export const initialStrategy = `
{
 "assetIsTokenZero":true,
 "minTick":-700000,
 "maxTick":700000,
 "feeTiers":[10000],
 "strategy":"0xdd2e3f45234182539bc4dd532d9fae3a4d63dbc7adf96452f541fce8539504f1",
 "midAllocationBps":200,
 "assetAllocationBps":300,
 "baseAllocationBps":100,
 "rangeSize":2,
 "assetRebalanceThreshold":1.5,
 "baseRebalanceThreshold":1,
 "version":1,
 "twapDuration":2000,
 "maxTwapDeviation":300,
 "baseMaxSwapAmount":"1",
 "assetMaxSwapAmount":"1",
 "baseMinVwapAmount":"1",
 "assetMinVwapAmount":"1",
 "baseMinRebalanceAmount":"0",
 "assetMinRebalanceAmount":"2",
 "maxGasPrice: 50000000000,
 "coolDownPeriod":3600000,
 "apiData":null
}
`;

export const getInitialVaultCreationParams = (chainId: number, walletAddress: `0x${string}`): VaultCreationParams => ({
  ownTokenAddress: '', // your token, e.g. AAVE
  baseTokenAddress: getWethAddressByChainId(chainId), // base token
  network: getNetworkNameFromChainId(chainId),
  feeTier: 10000,
  ownerAddress: walletAddress,
  ownTokenAmount: '0',
  baseTokenAmount: '0',
  gasTankAmount: 0,
  strategy: initialStrategy,
});

export type NetworkName = 'polygon' | 'ethereum' | 'optimism' | 'arbitrum' | 'base' | 'gnosis' | 'sepolia' | 'Unknown';

export type SetFormValue = UseFormSetValue<{
  ownTokenAddress: string;
  baseTokenAddress: string;
  ownTokenAmount: string;
  baseTokenAmount: string;
  ownerAddress: string;
  network: NetworkName;
  feeTier: number;
  gasTankAmount: number;
  strategy: string;
}>;

export type RegisterForm = UseFormRegister<{
  ownTokenAddress: string;
  baseTokenAddress: string;
  network: NetworkName;
  feeTier: number;
  ownTokenAmount: string;
  baseTokenAmount: string;
  gasTankAmount: number;
  strategy: string;
}>;

export type FormErrors = FieldErrors<{
  ownTokenAddress: string;
  baseTokenAddress: string;
  network: NetworkName;
  feeTier: number;
  ownTokenAmount: number;
  baseTokenAmount: number;
  gasTankAmount: number;
  strategy: string;
}>;

export type FormTrigger = UseFormTrigger<{
  ownTokenAddress: string;
  baseTokenAddress: string;
  network: NetworkName;
  feeTier: number;
  ownTokenAmount: number;
  baseTokenAmount: number;
  gasTankAmount: number;
  strategy: string;
}>;

export const ConfigDetailsResponseSchema = z.object({
  success: z.boolean(),
  priceImpacts: z.array(
    z.tuple([
      z.number(), // amount
      z.nullable(z.number()), // price impact negative
      z.nullable(z.number()), // price impact positive
    ]),
  ),
  price0: z.number(),
  price1: z.number(),
  positions: z.array(
    z.object({
      range: z.object({
        lowerPrice: z.number(),
        upperPrice: z.number(),
        lowerTick: z.number(),
        upperTick: z.number(),
        feeTier: z.number(),
      }),
      liquidity: z.number(),
      amount0: z.number(),
      amount1: z.number(),
    }),
  ),
});

export type ConfigDetailsResponse = z.infer<typeof ConfigDetailsResponseSchema>;

export enum RebalanceSpeed {
  reluctant = 'Reluctant',
  moderate = 'Moderate',
  aggressive = 'Aggressive',
}

export enum LiquidityConcentration {
  'risk averse' = 'RiskAverse',
  moderate = 'Moderate',
  risky = 'Risky',
}

export const GetVaultInfoResponseSchema = z.object({
  balance: z.bigint(),
  datas: z.string(),
  lastRebalance: z.bigint(),
  strat: z.string(),
  termEnd: z.bigint(),
});

export type GetVaultInfoResponse = z.infer<typeof GetVaultInfoResponseSchema>;

export type SimulationTab = 'liquidity' | 'price-impact';

export type ValidTokenRatio = 0.25 | 0.3 | 0.35 | 0.4 | 0.45 | 0.5 | 0.55 | 0.6 | 0.65 | 0.7 | 0.75;

export const ValidTokenRatios: ValidTokenRatio[] = [0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7, 0.75];

export const CreateVaultPayloadSchema = z.object({
  id: z.string(),
  token0: z.string(),
  token1: z.string(),
  chainId: z.number(),
  symbol0: z.string(),
  symbol1: z.string(),
  token0LogoUrl: z.string(),
  token1LogoUrl: z.string(),
  price0: z.number(),
  price1: z.number(),
  creator: z.string(),
  manager: z.string(),
  owner: z.string(),
  // totalSupply: z.string(),
  token0Decimals: z.number(),
  token1Decimals: z.number(),
  amount0: z.string(),
  amount1: z.string(),
  cmcPrice0: z.number(),
  cmcPrice1: z.number(),
  apiIds: z.object({
    cg0: z.string(),
    cg1: z.string(),
    cmc0: z.string(),
    cmc1: z.string(),
  }),
});

export type CreateVaultPayload = z.infer<typeof CreateVaultPayloadSchema>;
