import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { IVault } from '../../../types';
import { abbreviateNumber, capitalizeFirstLetter } from '../../../utils/n-formatter';
import { getVaultProportions } from '../../../utils/vault';
import { ManagerView } from '../managerView';
import GaugeLogo from './GaugeLogo';
import StrategyBadge from './StrategyBadge';

interface VaultV2ListItemProps {
  vault: IVault & { userBalance?: string };
  // withNavigation: updates the url when the user clicks on the expand/collapse button
  withNavigation?: boolean;
  selectedVaultId?: string | undefined;
  refetch: () => void;
}

export function VaultV2ListItem({ vault, selectedVaultId, withNavigation = false }: VaultV2ListItemProps) {
  const [showManager, setShowManager] = useState(selectedVaultId === vault.id);

  useEffect(() => {
    setShowManager(selectedVaultId === vault.id);
  }, [selectedVaultId, vault]);

  const [searchParams] = useSearchParams();

  const { symbol0, symbol1, tvl } = vault;

  const { percentage0Str, percentage1Str } = getVaultProportions(vault);

  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full p-6 min-h-[130px] relative bg-vault-black rounded-[24px] text-vault-white bg-opacity-80 hover:bg-opacity-80 hover:bg-[#1F1F21] duration-500 ease-in-out">
      <div className="flex flex-col md:flex-row  justify-between">
        {/* Token Logos */}
        <div className="flex min-w-[260px] ">
          <img
            src={vault.token0LogoUrl}
            alt={symbol0}
            className="rounded-full max-w-[40px] max-h-[40px] align-middle mx-0.5 mt-1 object-cover"
          />
          <img
            src={vault.token1LogoUrl}
            alt={symbol1}
            className="rounded-full max-w-[40px] max-h-[40px] align-middle mt-1 object-cover"
          />

          <div className="flex flex-col mx-2 ">
            <p className="text-vault-gray">{capitalizeFirstLetter(vault?.network)}</p>
            <p className=" text-[20px]">{vault.name}</p>
          </div>
        </div>

        {/* TVL */}
        <div className="min-w-[190px]">
          <div className="flex items-center gap-1">
            <p className="text-vault-gray">TVL</p>
          </div>
          <div className="flex flex-col">
            <p className="mx-1 text-[20px]">{tvl ? '$' + abbreviateNumber(tvl) : 'n/a'}</p>
            <p className="text-vault-gray mx-1 text-[14px] mt-1">
              {percentage0Str}% {symbol0.toUpperCase()} - {percentage1Str}% {symbol1.toUpperCase()}
            </p>
          </div>
        </div>

        <div className="min-w-[150px]">
          {vault.gauge ? (
            <div>
              {' '}
              <p className="text-vault-gray">Reward APR</p>
              <div className="flex flex-row">
                <div>
                  <div className="flex items-center gap-1">
                    <p className="text-[20px]">{(vault.gauge?.rewardApr || 0).toFixed(2)}%</p>
                    <GaugeLogo vault={vault} />
                  </div>
                  <p className="text-vault-gray text-[14px] mt-1">
                    Rewards {Number(vault.gauge?.rewardApr) > 0 ? 'active' : 'ended'}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <p className="text-vault-gray">Fee APR</p>
              <p className="text-[20px] mt-1">(New Vault)</p>
            </div>
          )}
        </div>
        <div className="min-w-[120px]">
          <p className="text-vault-gray mb-1">Strategy</p>

          <StrategyBadge symbol0={vault.symbol0} symbol1={vault.symbol1} />
        </div>
        {/* 
        <div>
           <Image src="/assets/images/water-drop-2.png" className="object-cover" width={32} alt="waterdrop" />
div
     
     </div> */}

        {!!vault?.userBalance && (
          <div>
            <p className="text-vault-gray">My Position</p>
            <div className="flex flex-row">
              <p className="mx-1 text-[20px]">${vault.userBalance}</p>
            </div>
          </div>
        )}

        {/* Expand/Collapse */}
        <div className="flex justify-end">
          <button>
            {showManager ? (
              <ChevronUpIcon
                onClick={() => {
                  if (withNavigation) {
                    navigate(`/v2-vaults?${searchParams.toString()}`);
                  } else {
                    setShowManager(false);
                  }
                }}
                className="h-6 w-6 text-vault-gray-hover"
              />
            ) : (
              <ChevronDownIcon
                onClick={() => {
                  if (withNavigation) {
                    navigate(`/v2-vaults/${vault.id}?${searchParams.toString()}`);
                  } else {
                    setShowManager(true);
                  }
                }}
                className="h-6 w-6 text-vault-gray-hover"
              />
            )}
          </button>
        </div>
      </div>
      {/* Expanded View */}
      {showManager && (
        <div className="mt-8">
          <ManagerView vault={vault} vaultType="v2" />
        </div>
      )}
    </div>
  );
}
