import { NetworkFilter } from '../types';

const VaultsListMainnet: string[] = ['0xc6729f6c7f26074935723895866f7fa272830ddc'];
export const VaultsListPolygon: string[] = [
  '0x2A1475Cf37BA162E47588aC9829B4b215f19da76',
  '0x6276B4A3478EcfCDc42Bd1863A50eCd1fE68f1Ac',
  '0x3407683AAEA55b1C45F5b48FF52Df7a1DB531bac',
  '0x09bcD6a34C678BdEF1e303Cdd8bf16cDd091D909',
  '0x9ed26f83D09b8c3cF27642452AdD818E53C1E0f3',
];

export const chainedVaults: { [key: number]: string[] } = {
  [1]: VaultsListMainnet,
  [137]: VaultsListPolygon,
};

export const networkFiltersV1: NetworkFilter[] = [
  {
    name: 'All',
    key: null,
    logoInnerHtml: `
    <div style="background: rgb(255 167 96); border-radius: 100%; height: 20px; width: 20px "></div>
    `,
  },
  {
    name: 'Ethereum',
    key: 'ethereum',
    logoInnerHtml: `
    <img
    src="https://assets.dex.guru/icons/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2-eth.png"
    width={20}
    height={20}
    alt="logo ethereum"
    className="mr-2"
  />`,
  },
  {
    name: 'Polygon',
    key: 'polygon',
    logoInnerHtml: `
    <img
    src="https://assets.dex.guru/icons/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270-polygon.png"
    alt="polygon logo"
    width={20}
    height={20}
    className="mr-2"
  />`,
  },
  {
    name: 'Optimism',
    key: 'optimism',
    logoInnerHtml: `
    <img
      src="https://assets.dex.guru/icons/0x4200000000000000000000000000000000000042-optimism.png"
      width={20}
      height={20}
      alt="optimism logo"
      className="mr-2"
                  />`,
  },
  {
    name: 'Arbitrum',
    key: 'arbitrum',
    logoInnerHtml: `
    <img src="https://assets.coingecko.com/coins/images/16547/standard/arb.jpg?1721358242" width={20} height={20} alt="arbitrum logo" className="mr-2" />
    `,
  },
  {
    name: 'Gnosis',
    key: 'gnosis',
    logoInnerHtml: `
    <img src="https://assets.coingecko.com/coins/images/662/standard/logo_square_simple_300px.png?1696501854" width={20} height={20} alt="gnosis logo" className="mr-2" />
    `,
  },
];

export const networkFiltersV2: NetworkFilter[] = [
  {
    name: 'All',
    key: null,
    logoInnerHtml: `
    <div style="background: rgb(255 167 96); border-radius: 100%; height: 20px; width: 20px "></div>
    `,
  },
  {
    name: 'Ethereum',
    key: 'ethereum',
    logoInnerHtml: `
    <img
    src="https://assets.dex.guru/icons/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2-eth.png"
    alt="ethereum logo"
    width={20}
    height={20}
    className="mr-2"
  />`,
  },
  {
    name: 'Polygon',
    key: 'polygon',
    logoInnerHtml: `
    <img
    src="https://assets.dex.guru/icons/0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270-polygon.png"
    alt="polygon logo"
    width={20}
    height={20}
    className="mr-2"
  />`,
  },
  {
    name: 'Optimism',
    key: 'optimism',
    logoInnerHtml: `
    <img
      src="https://assets.dex.guru/icons/0x4200000000000000000000000000000000000042-optimism.png"
      width={20}
      height={20}
      alt="logo ethereum"
      className="mr-2"
                  />`,
  },
  {
    name: 'Arbitrum',
    key: 'arbitrum',
    logoInnerHtml: `
    <Image src="https://assets.coingecko.com/coins/images/16547/standard/arb.jpg?1721358242" width={20} height={20} alt="logo ethereum" className="mr-2" />
    `,
  },
  {
    name: 'Gnosis',
    key: 'gnosis',
    logoInnerHtml: `
    <Image src="https://assets.coingecko.com/coins/images/662/standard/logo_square_simple_300px.png?1696501854" width={20} height={20} alt="gnosis logo" className="mr-2" />
    `,
  },
];

export const V2_MERKL_VAULTS = [
  '0xf06fda2664d1f88d19919e37034b92bf26896c61', // Arbitrum ARB incentives
  '0x4ca9fb1f302b6bd8421bad9debd22198eb6ab723', // Arbitrum ARB incentives
  '0xd44ea8b0bef5b56c24c7dd83d33415ed04bb7274', // Lido wstETH rewards
  '0xda4ba24a371aee34f0677cd1635bb430340fd055', // Lido wstETH rewards
  '0xd05004c869566f2f1bb166f0960481807442270a',
  '0xe10546bee6424213dd9c80edd55e90fea96e6e11',
  '0x99cac5a58be2e2d2ebe064cd978451573cc08cb5',
  '0x63968375018c4fe8b2206da34b716ebc9187b356',
  '0x669d770e6872edf91a2b0959b9e3b89c46cb7fbf', // OP/USDC on optimsim
  '0x5b98150ff4a122fb80bcdd23c8f825df7d732797', // ETH/USDC on optimsim
  '0x99a9a7fe70e5a0276543b406b48fef147bdf18dd', // WETH/USDC on arbitrum
  '0x32c2a3d571ae280fd8dc95faccf1f73d5f6ec2b4', // wUSDM/wstETH on arbitrum
];

export const getMinimumCardinalityByChainId = (chainId: number) => {
  switch (chainId) {
    case 1:
      return 60;
    case 10:
      return 200;
    case 137:
      return 200;
    case 42161:
      return 200;
    default:
      return 200;
  }
};

export const CONFIG_DETAILS_BASE_CONFIG = {
  skewLimitBPS: 7000,
  limitOrderToBPS: 6000,
  limitOrderMaxDistanceTicks: 488,
  maxLimitOrderBPS: 500,
  wideRangeAmountBPS: 9900,
  bootstrappingConfig: {
    bootstrappingSkewBPS: 8000,
    extraAllocationBPS: 600,
    midAllocationBPS: 400,
    staticRangeOffsetInTicks: 100,
    staticRangeLimitInTicks: 4000,
    staticRangeAllocationBPS: 2500,
    rangeSize: 2,
    rangeRebalanceThreshold: 1,
  },
};

export const CONFIG_DETAILS_ASSET_CONFIG = {
  skewLimitBPS: 7000,
  limitOrderToBPS: 6000,
  limitOrderMaxDistanceTicks: 488,
  maxLimitOrderBPS: 500,
  wideRangeAmountBPS: 9900,
  bootstrappingConfig: {
    bootstrappingSkewBPS: 8000,
    extraAllocationBPS: 600,
    midAllocationBPS: 400,
    staticRangeOffsetInTicks: 100,
    staticRangeLimitInTicks: 4000,
    staticRangeAllocationBPS: 2500,
    rangeSize: 2,
    rangeRebalanceThreshold: 1,
  },
};

export const DEFAULT_LOGO_URI = '/assets/images/question-mark.svg';
export const LIQUID_STAKING_TOKENS = ['wsteth', 'reth', 'pxeth'];
