import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { zeroAddress } from 'viem';
import { useAccount, useContractRead, useNetwork, useSwitchNetwork } from 'wagmi';
import { ArrakisV1GaugeAbi } from '../../../abis/ArrakisV1Gauge';
import { ArrakisV2GaugeAbi } from '../../../abis/ArrakisV2Gauge';
import { ArrakisV2HelperAbi } from '../../../abis/ArrakisV2Helper';
import { ArrakisVaultV1ProxyAbi } from '../../../abis/ArrakisVaultV1Proxy';
import { ARRAKIS_V2_HELPER_ADDRESS, V2_MERKL_VAULTS } from '../../../constants';
import { IVault } from '../../../types';
import { vaultTokenValueToUsd } from '../../../utils/calculate-tvl';
import { formatBigNumber } from '../../../utils/format-big-number';
import { ExplorerDataType, getExplorerLink } from '../../../utils/get-explorer-link';
import {
  capitalizeFirstLetter,
  getNetworkNameFromChainId,
  numberWithCommas,
  truncateEthAddress,
} from '../../../utils/n-formatter';
import SwitchNetworkButton from '../../elements/buttons/SwitchNetworkButton';
import DashboardLink from '../../elements/links/DashboardLink';
import VaultAddressLink from '../../elements/links/VaultAddressLink';
import DepositForm from '../../forms/DepositForm/DepositForm';
import DepositFormPalm from '../../forms/DepositForm/DepositFormPalm';
import DepositFormV2 from '../../forms/DepositForm/DepositFormV2';
import WithDrawForm from '../../forms/WithdrawForm';
import WithDrawFormPalm from '../../forms/WithdrawForm/WithdrawFormPalm';
import WithDrawFormV2 from '../../forms/WithdrawForm/WithdrawFormV2';
import MerklVault from '../MerklVault';
import ClaimRewards from '../claim-rewards';
import ConnectWallet from '../connect-wallet/ConnectWallet';
import GasTank from '../gasTank';
import PositionValue from '../position-value/PositionValue';
import './manager.css';
// eslint-disable-next-line import/no-unresolved
import ArrowBackIcon from '/assets/images/icon-back.svg';
// eslint-disable-next-line import/no-unresolved
import IconRight from '/assets/images/icon-right.svg';
// eslint-disable-next-line import/no-unresolved
import TvlChart from '../../charts/TvlChart';
import TransactionButtonWrapper from '../../elements/buttons/TransactionButtonWrapper';
// eslint-disable-next-line import/no-unresolved
import LockedImage from '/assets/images/locked.svg';
import { Image } from '../../Image';

interface ManagerViewProps {
  vault: IVault;
  onlyForOwner?: boolean;
  isOwner?: boolean;
  vaultType?: 'v1' | 'palm' | 'v2';
  isIncreaseLiquiditySuccess?: boolean;
  palmTvl?: number;
  refetchInvestedVaults?: () => void;
}

export function ManagerView({
  vault,
  onlyForOwner = false,
  isOwner = false,
  vaultType = 'v1',
  isIncreaseLiquiditySuccess,
  palmTvl,
  refetchInvestedVaults,
}: ManagerViewProps) {
  const [showDeposit, setShowDeposit] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [showOverview, setShowOverview] = useState(true);

  const { chain } = useNetwork();
  const { isLoading: isLoadingSwitchNetwork, switchNetwork } = useSwitchNetwork();

  const { address: ownAddress } = useAccount();

  // get user position in vault

  const { data: userBalance, isLoading: isLoadingUserBalance } = useContractRead({
    address: (vault.gauge?.address ?? vault.id) as '0x${string}',
    abi: ArrakisVaultV1ProxyAbi,
    functionName: 'balanceOf',
    args: [ownAddress as '0x${string}'],
    chainId: vault.chainId,
    enabled: !!ownAddress && showOverview,
    watch: true,
    // cacheTime: 10000,
  });

  const currentAmountUsd = userBalance ? vaultTokenValueToUsd(vault, userBalance) : '0.00';

  const underlyingBalancesConfigV1 = {
    address: vault.id as '0x${string}',
    abi: ArrakisVaultV1ProxyAbi,
    functionName: 'getUnderlyingBalances',
    chainId: vault.chainId,
    enabled: vaultType === 'v1',
    cacheTime: 10000,
  };

  const underlyingBalancesConfigV2 = {
    address: ARRAKIS_V2_HELPER_ADDRESS,
    abi: ArrakisV2HelperAbi,
    functionName: 'totalUnderlying',
    chainId: vault.chainId,
    enabled: vaultType === 'v2',
    args: [vault.id],
    cacheTime: 10000,
  };

  // get underlying token balances: these are the amounts of tokens that are in the vault from all users
  const { data: underlyingBalances } = useContractRead(
    // @ts-ignore
    vaultType === 'v1' ? underlyingBalancesConfigV1 : underlyingBalancesConfigV2,
  );

  // how many tokens 0/1 the user will receive with current withdraw percentage

  const userBalanceBN = userBalance ?? BigInt(0);

  const totalSupplyBN = BigInt(vault.totalSupply) ?? BigInt(0);

  // @ts-ignore
  const receivableAmount0String = underlyingBalances?.[0]
    ? formatBigNumber(
        (((underlyingBalances as [bigint, bigint])?.[0] ?? BigInt(0)) * userBalanceBN) /
          (totalSupplyBN > BigInt(0) ? totalSupplyBN : BigInt(1)),
        vault.token0Decimals,
      )
    : '0';

  const receivableAmount1String = (underlyingBalances as [bigint, bigint])?.[1]
    ? formatBigNumber(
        (((underlyingBalances as [bigint, bigint])?.[1] ?? BigInt(0)) * userBalanceBN) /
          (totalSupplyBN > BigInt(0) ? totalSupplyBN : BigInt(1)),
        vault.token1Decimals,
      )
    : '0';

  const isWithdrawButtonDisabled = vaultType === 'palm' ? false : Number(currentAmountUsd) <= 0;
  // check if user is allowed to deposit or withdraw in vault
  const isAllowedWithdrawDepositErrorMessage = useMemo(() => {
    // for now restrictions apply only to v2 strategy vaults

    if (vaultType !== 'v2' || !vault.restrictions) {
      return '';
    }

    let errorMessage = '';
    let isSupplyCapExceeded = false;

    if (vault.restrictions?.supplyCapExceeded) {
      errorMessage = 'The supply cap for this vault has been reached';
      isSupplyCapExceeded = true;
    }

    if (
      !ownAddress ||
      (vault.restrictions?.whiteList?.length > 0 && !vault.restrictions?.whiteList.includes(ownAddress))
    ) {
      isSupplyCapExceeded
        ? (errorMessage += ' and you are not whitelisted.')
        : (errorMessage = 'You are not whitelisted');
    }
    return errorMessage;
  }, [vault, ownAddress, vaultType]);

  const { data: claimableRewards, isLoading: isLoadingClaimableRewards } = useContractRead({
    address: vault.gauge?.address as '0x${string}',
    abi: ArrakisV1GaugeAbi,
    functionName: 'claimable_reward',
    args: [ownAddress, vault.gauge?.rewardToken],
    chainId: vault.chainId,
    enabled: !!vault.gauge,
    select: (data) => {
      const x = data
        ? Number(formatBigNumber(BigInt(data.toString()), vault.gauge?.decimals || 18, vault.gauge?.decimals))
        : 0;

      return x;
    },
    //select: data => data as bigint
    cacheTime: 10000,
  });

  const claimableRewardsAbi = vaultType === 'v2' ? ArrakisV2GaugeAbi : ArrakisV1GaugeAbi;

  const [showTvlChart, setShowTvlChart] = useState(false);

  return (
    <>
      <div>
        <div className="flex flex-col justify-center">
          {showOverview ? (
            ownAddress || vaultType === 'v2' ? (
              !onlyForOwner || isOwner ? (
                vaultType === 'v2' || vaultType === 'palm' || vault.chainId === chain?.id ? (
                  <div className={`flex flex-col gap-5 ${vaultType === 'v2' ? 'sm:flex-row  self-start' : ''}`}>
                    {' '}
                    {vault && (vaultType === 'v2' || vaultType === 'palm') ? (
                      <>
                        {vaultType === 'palm' && (
                          <button className="text-[20px] mt-5  items-center gap-2 hidden sm:flex">
                            Vault Data
                            {showTvlChart ? (
                              <ChevronUpIcon
                                onClick={() => {
                                  setShowTvlChart(false);
                                }}
                                className="h-6 w-6 text-vault-gray-hover"
                              />
                            ) : (
                              <ChevronDownIcon
                                onClick={() => {
                                  setShowTvlChart(true);
                                }}
                                className="h-6 w-6 text-vault-gray-hover"
                              />
                            )}
                          </button>
                        )}

                        {(showTvlChart || vaultType === 'v2') && (
                          <div
                            className={`hidden md:block w-full lg:w-[653px] ${
                              vaultType === 'v2' ? 'lg:h-[500px]' : 'lg:h-[450px]'
                            }`}
                          >
                            <TvlChart vault={vault} vaultType={vaultType} />
                          </div>
                        )}
                      </>
                    ) : (
                      vaultType !== 'v1' && (
                        <div className="text-vault-white flex items-center justify-center h-full">
                          No performance data available
                        </div>
                      )
                    )}
                    <div className="w-full sm:w-[280px]">
                      {vaultType === 'v1' && (
                        <div className="flex gap-1 flex-col">
                          <div className="flex items-center gap-2 text-[16px]">
                            <div>
                              <img
                                src={vault.token0LogoUrl}
                                alt={vault.symbol0}
                                className="rounded-full max-w-[20px] max-h-[20px] align-middle"
                              />
                            </div>
                            <div className="flex justify-between w-full">
                              <div className="text-vault-gray-hover">
                                {receivableAmount0String} {vault.symbol0?.toUpperCase() ?? ''}
                              </div>
                              <div className="text-vault-light">
                                $
                                {numberWithCommas(
                                  ((vault.price0 ?? vault.cmcPrice0) * Number(receivableAmount0String)).toFixed(2),
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center gap-2 text-[16px]">
                            <img
                              src={vault.token1LogoUrl}
                              alt={vault.symbol1}
                              className="rounded-full max-w-[20px] max-h-[20px] align-middle"
                            />
                            <div className="flex justify-between w-full">
                              <div className="text-vault-gray-hover ">
                                {' '}
                                {receivableAmount1String} {vault.symbol1?.toUpperCase() ?? ''}
                              </div>
                              <div className="text-vault-light ">
                                {' '}
                                $
                                {numberWithCommas(
                                  ((vault.price1 ?? vault.cmcPrice1) * Number(receivableAmount1String)).toFixed(2),
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {vaultType === 'v2' && ownAddress && (
                        <>
                          <PositionValue
                            currentAmountUsd={currentAmountUsd}
                            vault={vault}
                            receivableAmount0={receivableAmount0String}
                            receivableAmount1={receivableAmount1String}
                          />

                          {/*  <ReturnVsHodl vaultId={vault.id} /> */}
                        </>
                      )}
                      {vaultType === 'v2' && !ownAddress && <ConnectWallet />}
                      {ownAddress && (vaultType === 'v2' || vaultType === 'v1') && (
                        <div className="flex flex-row justify-between w-full gap-[8px] text-vault-black-primary my-3">
                          {vault.chainId === chain?.id ? (
                            <>
                              <button
                                disabled={!!isAllowedWithdrawDepositErrorMessage || isWithdrawButtonDisabled}
                                onClick={() => {
                                  setShowWithdraw(!showWithdraw);
                                  setShowOverview(false);
                                }}
                                className={`${
                                  isAllowedWithdrawDepositErrorMessage || isWithdrawButtonDisabled
                                    ? 'bg-vault-gray-2 text-vault-gray-hover'
                                    : 'ring-1  text-zinc-400 ring-white/10 hover:bg-white/5 hover:text-white duration-300'
                                }  w-[142px] h-[48px] rounded-lg`}
                              >
                                Remove
                              </button>
                              <button
                                disabled={!!isAllowedWithdrawDepositErrorMessage}
                                onClick={() => {
                                  setShowDeposit(!showDeposit);
                                  setShowOverview(false);
                                }}
                                className={`w-[142px] h-[48px] rounded-lg ${
                                  isAllowedWithdrawDepositErrorMessage
                                    ? 'bg-vault-gray-2 text-vault-gray-hover'
                                    : 'bg-gradient-to-r from-[#FFA760] to-[#F45020] hover:opacity-90 duration-300'
                                }`}
                              >
                                Add Liquidity
                              </button>
                            </>
                          ) : (
                            <SwitchNetworkButton
                              switchNetwork={switchNetwork}
                              isLoading={isLoadingSwitchNetwork}
                              chainId={vault.chainId}
                              text="Add / Remove"
                            />
                          )}
                        </div>
                      )}
                      {ownAddress && isAllowedWithdrawDepositErrorMessage && (
                        <div className="mt-4 text-[#f24d4dff] text-xs">{isAllowedWithdrawDepositErrorMessage}</div>
                      )}

                      <div className={`flex flex-col gap-4 ${vaultType !== 'palm' ? 'mt-10' : ''}`}>
                        <>
                          {(vault.gauge && Boolean(vault.gauge.address)) ||
                            (ownAddress && V2_MERKL_VAULTS.includes(vault.id.toLowerCase()) && (
                              <div className="relative">
                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                  <div className="w-full border-t border-vault-gray-hover" />
                                </div>
                                <div className="relative flex justify-center">
                                  <span className="bg-vault-black px-2 text-sm text-vault-light">Rewards</span>
                                </div>
                              </div>
                            ))}

                          {vault.gauge && vault.gauge.address && (
                            <ClaimRewards
                              vault={vault}
                              isLoadingClaimableRewards={isLoadingClaimableRewards}
                              claimableRewards={claimableRewards}
                              abi={claimableRewardsAbi}
                              ownAddress={ownAddress as string}
                            />
                          )}
                          {ownAddress && V2_MERKL_VAULTS.includes(vault.id.toLowerCase()) && (
                            <MerklVault vault={vault} />
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col sm:flex-row items-center sm:-mb-20 h-[330px]">
                    <Image src="/assets/images/locked.svg" alt="lock" />
                    <div className="flex flex-col items-center -mt-28 sm:mt-0">
                      <div className="text-center">
                        You have to be connected to the{' '}
                        <span className="text-vault-orange">
                          {capitalizeFirstLetter(getNetworkNameFromChainId(vault.chainId))}
                        </span>{' '}
                        network
                      </div>
                      <SwitchNetworkButton
                        switchNetwork={switchNetwork}
                        isLoading={isLoadingSwitchNetwork}
                        chainId={vault.chainId}
                      />
                    </div>
                  </div>
                )
              ) : (
                <div className="flex flex-col sm:flex-row items-center sm:-mb-20">
                  <img src={LockedImage} alt="lock" />

                  <div className="flex flex-col items-center -mt-28 sm:mt-0">
                    <div className="text-center">You are not the owner of this vault</div>
                  </div>
                </div>
              )
            ) : (
              <ConnectWallet />
            )
          ) : (
            <div className="w-full">
              <div className="flex bg-vault-gray-2 rounded-[12px] w-[155px] h-12 justify-center align-center mb-8">
                <button
                  onClick={() => {
                    setShowOverview(true);
                    setShowDeposit(false);
                    setShowWithdraw(false);
                  }}
                  className="text-bg-gradient-orange flex items-center gap-2"
                >
                  <img src={ArrowBackIcon} alt="view liquidity" height={20} width={20} />
                  View liquidity
                </button>
              </div>

              {showDeposit && vaultType === 'v1' && <DepositForm vault={vault} currentAmountUsd={currentAmountUsd} />}
              {showDeposit && vaultType === 'palm' && (
                <DepositFormPalm
                  vault={vault}
                  currentAmountUsd={palmTvl || 0}
                  isIncreaseLiquiditySuccess={isIncreaseLiquiditySuccess || false}
                />
              )}
              {showDeposit && vaultType === 'v2' && <DepositFormV2 vault={vault} currentAmountUsd={currentAmountUsd} />}

              {showWithdraw && vaultType === 'v1' && (
                <WithDrawForm
                  vault={vault}
                  userBalance={userBalance || BigInt(0)}
                  // @ts-ignore
                  underlyingBalances={underlyingBalances}
                  claimableRewards={claimableRewards}
                  refetchInvestedVaults={refetchInvestedVaults}
                />
              )}

              {showWithdraw && vaultType === 'v2' && (
                <WithDrawFormV2
                  vault={vault}
                  userBalance={userBalance || BigInt(0)}
                  // @ts-ignore
                  underlyingBalances={underlyingBalances}
                  claimableRewards={claimableRewards}
                />
              )}

              {showWithdraw && vaultType === 'palm' && (
                <WithDrawFormPalm vault={vault} claimableRewards={claimableRewards} />
              )}
            </div>
          )}
        </div>
      </div>

      {vaultType === 'palm' && <GasTank vaultId={vault.id} chainId={vault.chainId} />}

      <div className="flex flex-col lg:flex-row mt-8 gap-6">
        {vault.id !== zeroAddress && (
          <VaultAddressLink chainId={vault.chainId} address={vault.id} text="Vault Address:" />
        )}

        {vault.creator && <VaultAddressLink chainId={vault.chainId} address={vault.creator} text="Creator:" />}

        {vault.gauge && vault.gauge.address && (
          <div className="flex flex-col sm:flex-row items-left sm:items-center gap-1">
            <div className="text-vault-gray-hover text-[16px] tracking-[-.01em]">Gauge Address:</div>
            <div className="text-vault-light-gray text-[16px]  tracking-[-.01em] flex items-center gap-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getExplorerLink(vault.chainId, vault.gauge.address, ExplorerDataType.ADDRESS)}
                className="flex items-center gap-2"
              >
                <span>{truncateEthAddress(vault.gauge.address)}</span> <img src={IconRight} alt="check-icon" />{' '}
              </a>
            </div>
          </div>
        )}
        {['palm', 'v2'].includes(vaultType) && vault.id !== zeroAddress && <DashboardLink vaultId={vault.id} />}

        {currentAmountUsd && vaultType === 'v1' && (
          <div className="flex flex-col sm:flex-row items-left sm:items-center gap-1">
            <div className="text-vault-gray-hover text-[16px] tracking-[-.01em]">My Position:</div>
            <div className="text-vault-light-gray text-[16px]  tracking-[-.01em]  flex items-center gap-2">
              {isLoadingUserBalance ? (
                <Skeleton height="20px" width="30px" baseColor="#292828" highlightColor="#6e6d6d" duration={0.9} />
              ) : (
                <div>${numberWithCommas(currentAmountUsd)}</div>
              )}
            </div>
          </div>
        )}
      </div>

      {vaultType === 'palm' && (
        <TransactionButtonWrapper
          chainId={vault.chainId}
          buttonText="Change Network to deposit / close"
          className="w-full mt-6 ring-1 text-zinc-400 ring-white/10 hover:bg-white/5 hover:text-white duration-300 bg-transparent"
        >
          <div className="flex items-center gap-3 w-full mt-6">
            <button
              disabled={!!isAllowedWithdrawDepositErrorMessage}
              onClick={() => {
                setShowDeposit(!showDeposit);
                setShowOverview(false);
              }}
              className={`w-[142px] grow h-[48px] rounded-lg ${
                isAllowedWithdrawDepositErrorMessage
                  ? 'bg-vault-gray-2 text-vault-gray-hover'
                  : 'bg-gradient-to-r from-[#FFA760] to-[#F45020] hover:opacity-90 duration-300 text-vault-black'
              }`}
            >
              Add Liquidity
            </button>
            <button
              onClick={() => {
                setShowWithdraw(!showWithdraw);
                setShowOverview(false);
              }}
              className={`${
                isAllowedWithdrawDepositErrorMessage || isWithdrawButtonDisabled
                  ? 'bg-vault-gray-2 text-vault-gray-hover'
                  : 'ring-1  text-zinc-400 ring-white/10 hover:bg-white/5 hover:text-white duration-300'
              } h-[48px] rounded-lg px-4`}
            >
              Close
            </button>
          </div>
        </TransactionButtonWrapper>
      )}
    </>
  );
}
